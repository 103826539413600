import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, MainContainer, SidebarContainer } from '../styles';
import { useEffect, useState } from "react";
import { baseURL } from "settings";
import { api } from "services/api";
import { PaginationProps } from "interfaces/IPagination";
import Pagination from '@mui/material/Pagination'
import { useDebaunce } from "utils/debaunce";
import { PageContainer } from "components/PageContainer";
import { Popup } from "components/Popup";
import { DriverForm } from "components/PopupForms/DriverForm";
import { IVehicle } from "interfaces/IVehicle";
import { Toolbar } from "components/Toolbar";
import { DataCard } from 'components/DataCard';
import { DriverCard } from 'components/DataCard/DriverCard';
import { TabBar } from 'components/TabBar';
import { driverVehicleService } from 'services/driverVehicleService';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';

export function Drivers() {
  const [dataDriverTable, setDataDriverTable] = useState<PaginationProps<IVehicle>>({} as PaginationProps<IVehicle>)
  const [formData, setFormData] = useState<IVehicle>({} as IVehicle)
  const [searchTerm, setSearchTerm] = useState('');
  const [isPost, setPost] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false);
  const [params, setParams] = useState({ page: 1, pageSize: 30, search: '', orderBy: 'name', inUse: 1, excludeCdTeste: 'yes' })
  const [active, setActive] = useState(true)
  const [loading, setLoading] = useState(false);
  const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })

  const fetchData = () => {
    setLoading(true)
    api.get(`${baseURL}/v1/delivery/vehicle`, {
      params
    })
      .then((response) => {
        setDataDriverTable(response);
      })
      .catch(e => {
        toast.error("Houve um erro ao buscar os dados no sistema")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function onChangeParams(params: any) {
    setParams(state => ({ ...state, ...params }))
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    debaunceFilter({ page })
  }

  const handleAdd = () => {
    setPost(true)
    setFormData({} as IVehicle)
    setPopupVisible(true)
  }

  const handleEdit = (element: IVehicle) => {
    setPost(false)
    setPopupVisible(true)
    setFormData(element)
    setActive(element.inUse)
  }

  const handleDeactivate = () => {
    const vehicle: IVehicle = formData
    const { driver } = vehicle

    if (driver && driver.isLeader === true) {
      toast.error("Não é possível desativar um líder de time, retire-o da posição primeiro")
      return
    }
    setActive(!active)
    let newData = vehicle
    newData.inUse = !active
    let msg = newData.inUse ? "Ativando veículo..." : "Desativando veículo..."
    toast.info(msg)
    driverVehicleService.updateVehicle(newData)

    // Removes driver from team if it exists
    if (driver) {
      let newDriver = driver
      newDriver.teamId = "null"
      driverVehicleService.updateDriver(newDriver)
    }
  }

  useEffect(() => {
    fetchData()
  }, [params, popupVisible]);

  return (
    <Container>
      <Popup
        popupTitle={isPost ? "Cadastrar Informações" : "Motorista e Veículo"}
        open={popupVisible}
        isEditPopup={true}
        canDelete={isPost ? false : true}
        onDelete={handleDeactivate}
        deleteText={`${active ? 'Desativar' : 'Ativar'}`}
        deleteToggle={!active} // Toggles delete for a green button when inactive
        disableDelete={(formData.driver === null && active) ? true : false}
        setOpen={setPopupVisible}
        content={<DriverForm vehicle={formData} setVehicle={setFormData} isPost={isPost} active={active} />}
      />
      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Motoristas e Times</h3>
              <TabBar
                tabs={[
                  { label: "Motoristas", path: "/drivers" },
                  { label: "Times", path: "/teams" }
                ]}
                selectedPage="/drivers"
              />
              <Toolbar
                params={params}
                paramState={setParams}
                loadingState={loading}
                placeholder="Nome, Agile Code ou Placa"
                add={true}
                onAdd={handleAdd}
                page='Drivers'
              />
            </div>
            <div className="dataCardContainer">
              {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
              {dataDriverTable.results?.filter(item => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(element => (
                  <DataCard key={element.id}>
                    <DriverCard data={element} handleEdit={() => handleEdit(element)} key={element.id} />
                  </DataCard>
                ))}
            </div>
            <Pagination
              shape="rounded"
              count={dataDriverTable?.totalPages}
              page={params.page}
              onChange={handlePageChange}
              className='pagination'
            />
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}
