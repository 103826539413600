import SearchIcon from 'assets/searchIcon.svg'
import { RouteSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { IVehicleFilter } from 'interfaces/IVehicle';

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void,
  capacityData: PaginationProps<IVehicleFilter>
}

export function CapacitySelect({ open, setOpen, onChangeParams, capacityData }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCapacity, setSelectedCapacity] = useState<number[]>([])

  const handleCheckbox = async (element: number) => {
    if (selectedCapacity?.includes(element)) {
      setSelectedCapacity(state => ([...state?.filter((data: number) => data !== element)]));
    } else {
      setSelectedCapacity(state => ([...state, element]));
    }
  }

  useEffect(() => {
    onChangeParams({ "capacity": selectedCapacity.join(","), "page": 1 })
  }, [selectedCapacity]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  if (!open) return null

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <RouteSelectContainer>
        <div className="header">
          <div className="input">
            <img className="searchIcon" src={SearchIcon} alt="" />
            <Input
              className="inputBox"
              onChange={handleSearch}
              placeholder="Capacidade"
            />
          </div>
        </div>
        <div className="list">
          {capacityData?.results
            .filter((item, index, self) => index === self.findIndex((t) => t.capacity === item.capacity))
            .map(element => (
              <div className="entry" key={element.id} onClick={() => handleCheckbox(element?.capacity)}>
                <button type="button" className="entryName" key={element.id}>{`${element?.capacity}`}</button>
                <input
                  type="checkbox"
                  checked={selectedCapacity?.includes(element.capacity)}
                />
              </div>
            ))}
        </div>
      </RouteSelectContainer>
    </>
  )
}
