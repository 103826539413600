import styled from "styled-components";


export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`

  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    margin-bottom: 50px;
    width: 400px;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
  }


  .infos {
    margin-bottom: 24px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89BD23;
    color: #FFF;
    padding-left: 44px;
  }

  .toolBar {
    margin-top: 25px;
    display: flex;
    align-items: center;
    margin-right: 664px;
  }

  .input {
    height: 100%;
    position: relative;
  }

  .calendar {
    margin-left: 750px;
    position: absolute;
    top: 140px;
  }

  .headerTitle {
      padding: 0;
    }

  @media screen and (max-width: 769px){
    .toolbar .toolbarInput{
      width: 75vw;
    }

    .infos{
      margin-bottom: 0;
      h3{
        font-size: 17px;
      }
    }

    .entry input {
      width: 20%;
    }
    .calendar {
      margin-left: 350px;
      position: absolute;
      /* top: 100px; */
    }

  }
`

export const Line = styled.tr`
    /* width: 100%; */
    position: relative;
    height: 48px;

    .id, .driverName, .clientName {
      word-wrap: break-word;

      :hover {
        overflow: visible;
        white-space: normal;
      }
    }

    .id {
      padding-left: 3%;
    }

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }

    .deliveryTime {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }

    .observation {
      padding-top: 4px;
    }

    .mall {
      padding-top: 4px;	
    }

    .edit {
      padding-right: 24px;
    }

    .statusBall {
      position: absolute;
      height: 12px;
      width: 12px;
      top: 0;
      bottom: 0;
      left: 1%;
      margin: auto;
      border-radius: 50%;
      background-color: #FFC107;
  }

  @media screen and (max-width: 769px){
    .statusBall {
      height: 100%;
      width: 6px;
      border-radius: 0;
      left: 0;
    }
    .id {
      padding-left: 10px;
      font-size: 0.85rem;
    }
    .deliveryTime {
      gap: 4px;
    }
  }
`
