import dayjs from "dayjs";
import { FilterSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import arrowFilter from 'assets/arrowFilter.svg'
import { Calendar } from "components/Calendar";

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void,
  onChangeParamsDate: (x: any) => void
}

const today = new Date();
const last7Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

const formatDate = (date: any) => {
  return dayjs(date).format('YYYY-MM-DD');
}

const initialEntries = [
  {
    id: 1,
    name: "últimos 7 dias",
    query: formatDate(last7Days),
    isChecked: false,
  },
];


export function SelectDate({ open, setOpen, onChangeParams, onChangeParamsDate }: IProps) {
  const [selectedDate, setSelectedDate] = useState<string[]>([])
  const [entriesState, setEntriesState] = useState(initialEntries);
  const [dateSelect, setDateSelect] = useState(false)
  const [selectedCheckbox, setSelectedCheckbox] = useState<number>(-1);

  const handleCheckbox = async (index: number, element: string) => {
    const newSelectedDates = [...selectedDate];
    if (selectedCheckbox === index) {
      setSelectedCheckbox(-1);
      newSelectedDates.splice(newSelectedDates.indexOf(element), 1);
    } else {
      setSelectedCheckbox(index);
      newSelectedDates.splice(0, newSelectedDates.length, element);
    }
    setSelectedDate(newSelectedDates);
  };

  useEffect(() => {
    if (selectedDate.length > 0) {
      onChangeParams({ "minDate": selectedDate.join(","), "maxDate": formatDate(today) })
    }
    else {
      onChangeParams({ "minDate": formatDate(today), "maxDate": formatDate(today) })
    }
  }, [selectedDate]);

  const display = open ? 'initial' : 'none'

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }}
        style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <div className="list">
          {entriesState.map((item, index) => (
            <div className='entry' key={item.id} onClick={() => handleCheckbox(index, item.query)}>
              <button type="button" className="entryName">{item.name}</button>
              <span className={`bullet ${selectedCheckbox === index ? 'checked' : ''}`}></span>
            </div>
          ))}
          <div className="entry">
            <button type="button" className="entryName" onClick={() => setDateSelect(true)}>Calendário</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
        </div>
        <Calendar dateRangeLimit={14} onChangeParamsDate={onChangeParamsDate} onChangeParams={onChangeParams} open={dateSelect} setOpen={setDateSelect} />
      </FilterSelectContainer>
    </>
  )
}
