import { IDriver } from 'interfaces/IDriver';
import { ITeams } from 'interfaces/ITeams';
import { IVehicle } from 'interfaces/IVehicle';
import { toast } from 'react-toastify';
import { baseURL } from 'settings';

import { api as apiService, ApiService } from './api'


interface IRegisterVehicle {
  id?: number,
  driverId?: number,
  name: string,
  agileUniqueCode: string,
  distributionCenter: string,
  transshipId?: number,
  licensePlate: string,
  model: string,
  color: string,
  capacity: number
}

class DriverVehicleService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) { }

  public updateDriver = async (element: IDriver, notify?: boolean) => {
    const params = {
      driverId: element.id,
      name: element.name,
      surname: element?.surname,
      agileCode: element?.agileCode,
      phoneNumber: element?.phoneNumber,
      teamId: element?.teamId,
      isLeader: element?.isLeader,
      isSupport: element?.isSupport,
      isUnavailable: element?.isUnavailable
    }

    let response
    try {
      response = await this.api.put(`${this.baseURL}/v1/delivery/driver`, params)
      notify && toast.success("Dados do motorista salvos com sucesso")
      return response
    } catch (e) {
      notify && toast.error("Houve um erro ao salvar os dados do motorista")
    }
  }

  public updateVehicle = async (element: IVehicle, notify?: boolean) => {
    const params = {
      name: element.name,
      capacity: element.capacity,
      model: element.model,
      driverId: element.driver?.id,
      agileId: element.agileId,
      vehicleId: element.id,
      distributionCenter: element.distributionCenter,
      transshipPointId: element.transshipPoint?.id,
      agileUniqueCode: element.agileUniqueCode,
      licensePlate: element.licensePlate,
      inUse: element.inUse,
    }

    try {
      let response = await this.api.put(`${this.baseURL}/v1/delivery/vehicle`, params)
      notify && toast.success("dados do veículo salvos com sucesso")
      return response
    } catch (e) {
      toast.error("Houve um erro ao salvar os dados do veículo")
    }
  }

  public registerVehicle = async (element: IRegisterVehicle) => {
    const params = {
      id: element.id,
      name: element.name,
      model: element.model,
      capacity: element.capacity,
      distributionCenter: element.distributionCenter,
      transshipPointId: element.transshipId,
      licensePlate: element.licensePlate,
      agileId: element.id,
      agileUniqueCode: element.agileUniqueCode,
      driverId: element.driverId,
      inUse: true
    }

    try {
      const error = await this.checkAgileCode('vehicle', params.agileUniqueCode) || await this.checkLicensePlate(params.licensePlate)
      if (error) return
      const response = await this.api.post(`${this.baseURL}/v1/delivery/vehicle`, params)
      return response
    } catch (e) {
      toast.error("Houve um erro ao cadastrar o veículo")
    }
  }

  public getDriverStatus = async (params: any) => {
    return await this.api.get(`${this.baseURL}/v1/delivery/driver/status`, { params });
  };

  public getVehicleDrivers = async (params: any) => {
    return await this.api.get(`${this.baseURL}/v1/delivery/vehicle`, { params });
  };

  public deleteDriverStatus = async (id: number) => {
    const data = { id };
    await this.api.delete(`${this.baseURL}/v1/delivery/driver/status`, {
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  public registerDriver = async (element: IDriver) => {
    const params = {
      id: element.id,
      name: element.name,
      surname: element.surname,
      phoneNumber: element.phoneNumber,
      agileCode: element.agileCode,
      agilePassword: element.agilePassword
    }

    try {
      let error = await this.checkAgileCode('driver', params.agileCode, true)

      if (error) return
      // Register Driver
      const response = await this.api.post(`${this.baseURL}/v1/delivery/driver`, params)
      toast.success("Motorista cadastrado com sucesso!")
      return response
    } catch (e) {
      toast.error("Houve um erro ao cadastrar o motorista!")
    }
  }

  public removeDriver = async (element: IVehicle): Promise<any> => {
    const params = {
      agileId: element.agileId,
      vehicleId: element.id,
      driverId: "null"
    }

    try {
      const response = await this.api.put(`${this.baseURL}/v1/delivery/vehicle`, params)
      toast.info("Motorista removido")
      return response
    } catch (e) {
      toast.error("Não foi possível remover o motorista")
    }
  }

  public deleteDriver = async (element: IVehicle) => {
    const driver = element.driver
    const team : ITeams = await this.getTeam(driver?.team?.id ?? 0).catch(() => {
      toast.error("Erro ao encontrar os times no banco de dados")
      return {} as ITeams
    })

    const params = {
      driverId: driver?.id,
      agileId: driver?.agileId
    }

    // Cannot delete driver if it's a team leader
    if (driver?.id === team?.leader?.leader?.id) {
      toast.error("Não é possível deletar um líder de time")
      return
    }

    // Remove Driver from Vehicle
    await this.removeDriver(element)

    try {
      // Finally, delete driver from DB
      await this.api.delete(`${this.baseURL}/v1/delivery/driver`, {data: params})

      toast.success("Motorista deletado com sucesso!")

    } catch (e) {
      toast.error("Não foi possível deletar o motorista")
    }
  }

  public getTeam = async (id: number): Promise<any> => {
    const params = {
      teamId: id
    }

    try {
      const response = await this.api.get(`${this.baseURL}/v1/delivery/team`, { params })
      return response.results[0]
    } catch (e) {
      toast.error("Não foi possível buscar o time no banco de dados")
    }
  }

  public removeTeamLeader = async (id: number): Promise<any> => {
    const params = {
      teamId: id,
      leaderData: "null"
    }

    try {
      const response = await this.api.put(`${this.baseURL}/v1/delivery/team`, params)
      return response
    } catch (e) {
      toast.error("Não foi possível remover o líder do time")
    }
  }

  public async checkAgileCode(endpoint: string, code: string, notify?: boolean) {
    let error: boolean = false
    await this.api.get(`${this.baseURL}/v1/delivery/${endpoint}`, { params: { agileCode: code, agileUniqueCode: code } })
      .catch(() => toast.error("Não foi possível verificar por código agile duplicado."))
      .then((response) => {
        if (response.results.length > 0) {
          notify && toast.error("Código Agile já existe no sistema!")
          error = true
        }
      })
    return error
  }

  public async checkLicensePlate(plate: string) {
    let error: boolean = false
    await this.api.get(`${this.baseURL}/v1/delivery/vehicle`, { params: { licensePlate: plate } })
      .catch(() => toast.error("Não foi possível verificar por placa duplicada."))
      .then((response) => {
        if (response.results.length > 0) {
          toast.error("Placa já existe no sistema!")
          error = true
        }
      })
    return error
  }

  public getDrivers = (params: any) => {
    return this.api.get(`${this.baseURL}/v1/delivery/driver`, {params})
  }
}


export const driverVehicleService = new DriverVehicleService(apiService, baseURL)
