
import arrowFilter from 'assets/arrowFilter.svg'
import CustomCheckBox, { ICheckboxOption } from 'components/checkbox'
import { useState } from 'react'
import { FilterSelectContainer, SelectBack } from './styles'
import { TeamSelectDownloadPDF } from './SelectPdfTeam'

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParamsTeam: (x: any) => void,
  checkboxes?: ICheckboxOption[]
}

export function FilterRoutes({ open, setOpen, onChangeParamsTeam, checkboxes }: IProps) {
  const [pdfTeamSelected, setPdfTeamSelected] = useState(false)

  const display = open ? 'initial' : 'none'

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }}
        style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <div className="list">
          <div className="entry" onClick={() => setPdfTeamSelected(true)}>
            <button type="button" className="entryName">Selecionar Pdf por Time</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
          {checkboxes && checkboxes.map((checkbox) => (
            <div className="checkboxes" onClick={checkbox.handleToggle}>
              <button type="button" className="entryName">{checkbox.text}</button>
              <CustomCheckBox
                key={checkbox.id}
                size="small"
                checked={checkbox.state}
              />
            </div>
          ))}
        </div>
        <TeamSelectDownloadPDF open={pdfTeamSelected} setOpen={setPdfTeamSelected} onChangeParamsTeam={onChangeParamsTeam} />
      </FilterSelectContainer>
    </>
  )
}
