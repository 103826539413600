import { IBoxCount, IRouteTag } from "interfaces/IBoxes";
import { OrderEntry } from "./OrderEntry";
import { FormContainer } from "./styles";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { boxControlService } from "services/boxControlService";
import { LinearProgress } from "@mui/material";

interface IProps {
    route: number,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isCount: boolean,
    handleBoxAmount: (x: number) => void,
    page?: string
}

type BoxDataList = { [orderId: string]: IBoxCount }

export function BoxDivergenceForm({ route, handleBoxAmount, isCount, setOpen, page }: IProps) {
    const [data, setData] = useState<IRouteTag[]>([] as IRouteTag[])
    const [countList, setCountList] = useState<IBoxCount[]>([] as IBoxCount[])
    const [updatedList, setUpdatedList] = useState<IBoxCount[]>([] as IBoxCount[])
    const [loading, setLoading] = useState(false)
    const [totalBoxes, setTotalBoxes] = useState(0)
    const [boxDataList, setBoxDataList] = useState<BoxDataList>({})
    const [dataLoaded, setDataLoaded] = useState(false)

    const totalCount = useRef(0)

    const fetchData = () => {
        setLoading(true)
        boxControlService.getOrdersByRoute(route)
            .then(response => {
                setData(response.results)
            }).catch(e => {
                toast.error("Houve um erro ao buscar os pedidos da rota")
            }).finally(() => {
                setLoading(false)
            })
    }

    const fetchCountData = () => {
        boxControlService.getOrdersBoxCount({ route })
            .then(response => {
                setCountList(response.results)
                getBoxData(response.results)
            }).catch(e => {
                toast.error("Houve um erro ao buscar as contagens de caixa no sistema")
            })
    }

    const handleUpdate = (element: IBoxCount, remove: boolean) => {
        const newList = [...updatedList]
        const index = newList.findIndex(count => count.orderId === element.orderId)

        if (index !== -1) {
            newList[index] = element
        } else {
            newList.push(element)
        }

        setUpdatedList(remove ? newList.filter(count => count.orderId !== element.orderId) : newList)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (updatedList.length === 0) return
        toast.info("Enviando dados...")
        boxControlService.updateBoxCount(updatedList)
            .then(response => {
                handleBoxAmount(totalCount.current)
                setUpdatedList([])
                setOpen(false)
            }).catch(e => {
                toast.error("Não foi possível alterar a contagem de caixas para esse pedido")
            })
    }

    const getBoxData = (list: IBoxCount[]) => {
        const newList: BoxDataList = list.reduce((acc, box) => {
            if (!acc[box.orderId]) {
                acc[box.orderId] = box
            }
            return acc
        }, {} as BoxDataList)
        setBoxDataList(newList)
        setDataLoaded(true)
    }

    const getTotalBoxes = (list: IBoxCount[]) => {
        const newTotal = list.reduce((total, entry) => {
            entry = {
                ...entry,
                boxesMissing: isCount ? entry.boxesSent - (entry.boxesConfirmed ?? entry.boxesSent)
                    : entry.boxesConfirmed ?? entry.boxesSent - (entry.boxesReturned ?? entry.boxesSent)
            }
            return total + (entry?.boxesMissing || 0)
        }, 0)

        return newTotal
    }

    const mergeLists = () => {
        const mergedList = countList.slice()

        updatedList.forEach(updatedEntry => {
            const existingEntryIndex = mergedList.findIndex(
                countEntry => countEntry.orderId === updatedEntry.orderId
            )

            if (existingEntryIndex !== -1) {
                mergedList[existingEntryIndex] = updatedEntry
            } else {
                mergedList.push(updatedEntry)
            }
        })

        return mergedList
    }

    useEffect(() => {
        fetchData()
        fetchCountData()
    }, [])

    const joinedData = mergeLists()

    useEffect(() => {
        const newTotal = getTotalBoxes(joinedData)
        setTotalBoxes(newTotal)
        totalCount.current = newTotal
    }, [countList, updatedList])

    return (
        <FormContainer id="popupForm" onSubmit={e => handleSubmit(e)}>
            <div className="col1">
                <div className="section">
                    <div className="form vertical">
                        <div className="headers">
                            <span className="small">Pedido</span>
                            <span className="large">Restaurante</span>
                            <span className="small">Caixas faltando</span>
                        </div>
                        <div className="orderList">
                            {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
                            {dataLoaded && data.sort((a, b) => parseInt(b.orderId) - parseInt(a.orderId)).map(order => (
                                <OrderEntry boxData={boxDataList[order.orderId]} data={order} isCount={isCount} handleSave={handleUpdate} key={order.orderId} />
                            ))}
                        </div>
                        <div className="footer">
                            <span>Total:</span>
                            <span>{totalBoxes}</span>
                        </div>
                    </div>
                </div>
            </div>
        </FormContainer>
    )
}
