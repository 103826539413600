import styled from "styled-components";

export const MallFilterContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  .mallFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 103px;
    height: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
    font-size: 14px;
    border: none;

    gap: 5px;
  }

  .active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const StatusFilterContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  .statusDelivery {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 103px;
    height: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
    font-size: 14px;
    border: none;
  }

  .shopingFilter {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 12px;
    width: 103px;
    height: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
    font-size: 14px;
    border: none;
  }

  .active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .statusBall {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #EB001B;
  }

  .statusBallBlue {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #3887FF;
  }

  .statusBallGreen {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #04D361;
  }
`
