import { driverVehicleSchema } from "schemas"
import { useFormik } from "formik"
import { Input } from "components/Input"
import { IVehicle } from "interfaces/IVehicle"
import { Button } from "components/Button"
import { useState } from "react"
import { driverVehicleService } from "services/driverVehicleService"
import { DriverSelect } from "./DriverSelect"
import { IDriver } from "interfaces/IDriver"
import { mask, unMask } from "remask"
import { RegisterForm } from "../RegisterForm"
import { DropdownButton } from "components/DropdownButton"
import { defaultTheme } from "styles/theme/default"
import { DistSelect } from "./DistSelect"
import { IDist } from "interfaces/IDist"
import { ModelSelect } from "./ModelSelect"
import { DriverFormContainer } from "./styles"

interface IProps {
    vehicle: IVehicle,
    setVehicle: React.Dispatch<React.SetStateAction<IVehicle>>,
    isPost: boolean,
    active: boolean
}

export const DriverForm = ({ vehicle, isPost, setVehicle, active }: IProps) => {
    const [driverSelect, setDriverSelect] = useState(false)
    const [id, setId] = useState(vehicle.driver?.id)
    const [dist, setDist] = useState(vehicle.distributionCenter || "")
    const [distId, setDistId] = useState(vehicle.transshipPoint?.id || 0)
    const [vehicleModel, setModel] = useState(vehicle.model || "Sem Modelo")
    const [open, setOpen] = useState(false)
    const [modelOpen, setModelOpen] = useState(false)
    // const [agileCode, setCode] = useState(vehicle.driver?.agileCode)

    const phonePatterns = ['(99) 9999-9999', '(99) 9 9999-9999']
    const cpfPatterns = ['999.999.999-9', '999.999.999-99']

    const { driver, capacity } = vehicle

    const formik = useFormik({
        initialValues: {
            name: driver ? (driver.name + (driver.surname ? " " + driver.surname : "")) : "",
            // checks if agileCode and phone are existent, then if they are not empty strings
            agileCode: driver ? (driver.agileCode && mask(driver.agileCode, cpfPatterns)) || "" : "",
            phone: driver ? (driver.phoneNumber && mask(driver.phoneNumber, phonePatterns)) || "" : "",
            capacity: capacity || 0,
            licensePlate: vehicle.licensePlate || ""
        },
        onSubmit: (values) => {
            // let newElement = vehicle
            const newValues = {
                name: values.name.trim(),
                capacity: values.capacity,
                model: vehicleModel,
                licensePlate: values.licensePlate,
                distributionCenter: dist,
                transshipId: distId,
                driver: {
                    id: id,
                    name: values.name.split(' ')[0],
                    surname: values.name.split(' ').slice(1).join(" ").trim(),
                    phoneNumber: unMask(values.phone),
                    agileCode: unMask(values.agileCode)
                }
            }

            const newElement: IVehicle = { ...vehicle, ...newValues } as IVehicle
            driverVehicleService.updateDriver(newElement.driver)
            driverVehicleService.updateVehicle(newElement, true)
            setVehicle(newElement)
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: active ? driverVehicleSchema : null
    })

    const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } = formik

    const handleSelect = (element: IDriver) => {
        setFieldValue('name', `${element.name} ${element.surname || ""}`)
        setFieldValue('agileCode', element.agileCode)
        setFieldValue('phone', element.phoneNumber || "")
        setId(element.id || 0)
    }

    const handleSelectDist = (element: IDist) => {
        setDist(element.name)
        setDistId(element.id)
    }

    const handleSelectModel = (element: string) => {
        setModel(element)
    }

    const handleRemove = () => {
        if (!id) {
            return
        }
        driverVehicleService.removeDriver(vehicle)
        setFieldValue("name", "")
        setFieldValue("phone", "")
        setFieldValue("agileCode", "")
        setId(0)
    }

    // Return appropriate popups
    if (isPost) {
        return (
            <RegisterForm />
        )
    }

    return (
        <DriverFormContainer id="popupForm" onSubmit={handleSubmit}>
            <div className="col1">
                <div className="section">
                    <h2 className="sectionTitle">Motorista</h2>
                    <div className="form">
                        <label htmlFor="selectDriver" className="formName">Disponíveis</label>
                        <div className="driverSelectContainer">
                            <Button text="Adicionar" variant="secondary" className="driverBtn selectBtn"
                                type="button" onClick={() => setDriverSelect(true)} disabled={!active} />
                            <Button text="Remover" variant="secondary" className="driverBtn removeBtn"
                                type="button" onClick={handleRemove} disabled={!active} />
                        </div>
                        <DriverSelect open={driverSelect} setOpen={setDriverSelect} handleSelect={handleSelect} unassigned tailPosition="left" />
                    </div>
                    <div className="form">
                        <label htmlFor="id" className="formName">ID</label>
                        <Input type="text" name="id" className="medInput" value={id || "Sem Motorista"} readOnly />
                    </div>
                    <div className="form">
                        <label htmlFor="agileCode" className="formName">CPF</label>
                        <Input type="text" name="agileCode" className="bigInput"
                            onChange={(e) => {
                                let originalValue = unMask(e.target.value)
                                e.target.value = mask(originalValue, cpfPatterns)
                                handleChange(e)
                            }}
                            onBlur={handleBlur} error={errors.agileCode} value={values.agileCode}
                            disabled={!driver || !active} />
                    </div>
                    <div className="form">
                        <label htmlFor="name" className="formName">Nome</label>
                        <Input type="text" name="name" className="bigInput" onChange={handleChange} onBlur={handleBlur} error={errors.name} value={values.name} disabled={!active} />
                    </div>
                    <div className="form">
                        <label htmlFor="phone" className="formName">Tel/Cel</label>
                        <Input type="tel" name="phone" className="medInput"
                            onChange={(e) => {
                                let originalValue = unMask(e.target.value)
                                e.target.value = mask(originalValue, phonePatterns)
                                handleChange(e)
                            }} onBlur={handleBlur} error={errors.phone} value={values.phone} disabled={!active} />
                    </div>
                </div>
            </div>
            <div className="col2">
                <div className="section">
                    <h2 className="sectionTitle">Dados do Veículo</h2>
                    <div className="form">
                        <label htmlFor="agileId" className="formName">ID Agile</label>
                        <Input type="text" name="agileId" className="medInput" value={vehicle.agileId} readOnly />
                    </div>
                    <div className="form">
                        <label htmlFor="model" className="formName">Centro Dist.</label>
                        <DropdownButton
                            text={dist}
                            bgColor={defaultTheme.colors['base-input']}
                            content={<DistSelect open={open} setOpen={setOpen} handleSelect={handleSelectDist} />}
                            select={open}
                            setSelect={setOpen} />
                    </div>
                    <div className="form">
                        <label htmlFor="model" className="formName">Modelo</label>
                        <DropdownButton
                            text={vehicleModel}
                            bgColor={defaultTheme.colors['base-input']}
                            content={<ModelSelect open={modelOpen} setOpen={setModelOpen} handleSelect={handleSelectModel} />}
                            select={modelOpen}
                            setSelect={setModelOpen} />
                    </div>
                    <div className="form">
                        <label htmlFor="licensePlate" className="formName">Placa</label>
                        <Input type="text" name="licensePlate" className="smallInput"
                            onChange={(e) => {
                                e.target.value = e.target.value.toLocaleUpperCase()
                                handleChange(e)
                            }}
                            onBlur={handleBlur} error={errors.licensePlate} value={values.licensePlate} disabled={!active} />
                    </div>
                    <div className="form">
                        <label htmlFor="capacity" className="formName">Capacidade</label>
                        <Input type="number" name="capacity" className="smallInput"
                            onChange={handleChange} onBlur={handleBlur} error={errors.capacity} value={values.capacity} disabled={!active} />
                    </div>
                </div>
            </div>
        </DriverFormContainer>
    )
}
