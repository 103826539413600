import { IAuthentication } from 'interfaces/IAuthentication'
import { baseURL } from 'settings';
import { dateToday } from 'utils/dateToday';

import { api as apiService, ApiService } from './api'


class DeliveryService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) { }

  public uploadRouteFile = async (file: FormData): Promise<IAuthentication> => {
    const date = dateToday()
    return await this.api.put(`${this.baseURL}/v1/delivery/boxes-sent/upload/arquivo?deliveryDate=${date}`, file);
  }
}

export const deliveryService = new DeliveryService(apiService, baseURL)
