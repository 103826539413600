import { EditButton } from "components/EditButton"
import { IBoxCountData, IBoxes, ISensitiveData } from "interfaces/IBoxes"
import { Line } from "../styles"
import { useState } from "react"
import { DriverSelectBtnContainer, ObsBtnContainer, SensitiveBtnContainer } from "./styles";
import ObsIcon from 'assets/obsGray.svg'
import ObsNewIcon from 'assets/obsNotification.svg'
import LoopIcon from '@mui/icons-material/Loop';
import EggIcon from 'assets/brokenEggIcon.svg'
import { DriverSelect } from "components/PopupForms/DriverForm/DriverSelect";
import { routeService } from "services/routeService";
import { IWaybill } from "interfaces/IWaybill";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { boxControlService } from "services/boxControlService";
import { IVehicle } from "interfaces/IVehicle";
import { IDriver } from "interfaces/IDriver";

interface IProps {
    element: IBoxes,
    toggle: boolean,
    onSubmit: (x: IBoxes) => void,
    handleEdit: (x: IBoxes) => void,
    handleEditCount: (data: IBoxCountData) => void,
    handleEditSensitive: (x: ISensitiveData) => void
}

export const FormTableLine = ({ element, toggle, onSubmit, handleEdit, handleEditCount, handleEditSensitive }: IProps) => {
    const smallScreen = useMediaQuery({ maxWidth: 820 })
    const [boxesConfirmed, setBoxesConfirmed] = useState(element.boxesConfirmed || element.boxesSent)
    const [selectVisible, setSelectVisible] = useState(false)
    const [data, setData] = useState<IBoxes>(element)
    const [loading, setLoading] = useState(false)

    const fetchWaybill = (driver: IDriver) => {
        toast.info("Aguarde...")
        routeService.getWaybill(data.route)
            .then(response => {
                driver.vehicles && handleDriverSelect(driver.vehicles[0], response.results[0].id, driver?.name)
            }).catch(() => {
                toast.error("Não foi possível encontrar um Waybill para a rota especificada")
            })
    }

    const handleBoxAmount = (boxes: number) => {
        const finalValue = element.boxesSent - boxes
        if (finalValue === boxesConfirmed) return
        setBoxesConfirmed(finalValue)
        const newElement = { ...element, boxesConfirmed: finalValue }
        onSubmit(newElement)
    }

    const handleDriverSelect = async (vehicle: IVehicle, waybillId: number, driverName: string) => {
        if (vehicle && vehicle.id !== undefined) {
            const params = {
                waybillId: waybillId,
                vehicleId: vehicle.id,
            }
            setLoading(true)
            try {
                await routeService.putLineup(params)
                await routeService.updateLineup([params])

                setData({
                    ...element,
                    vehicle: {
                        ...vehicle,
                        driver: {
                            ...data.vehicle.driver,
                            name: driverName
                        }
                    }
                })

                toast.success("Motorista atualizado com sucesso!")
            } catch (error) {
                toast.error("Ocorreu um erro ao atualizar o motorista no sistema")
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <Line className="bodyLine">
            <td align="left" className='contentTable' style={{ paddingLeft: "2%" }}>
                <DriverSelectBtnContainer className="driverSelectBtn" onClick={() => setSelectVisible(true)} disabled={loading}>
                    <LoopIcon />
                </DriverSelectBtnContainer>
                <span>{toggle ? (data.route || "Sem Rota") : (data.vehicle?.driver?.name || "Sem Nome")}</span>
                {selectVisible &&
                    <DriverSelect
                        open={selectVisible} setOpen={setSelectVisible}
                        handleSelect={fetchWaybill}
                        style={smallScreen ?
                            {
                                bottom: '-45vh',
                            } : {
                                top: 'inherit',
                                left: '0',
                                transform: 'translateY(20px)'
                            }
                        }
                        tailPosition="top"
                    />
                }
            </td>
            <td
                align="center"
                className='contentTable'
                onClick={(e: any) => e.target?.children[0]?.focus()}>
                <div className="boxAmount">
                    {data.boxesSent ?
                        <>
                            <span className="boxesReturned">{boxesConfirmed}</span>
                            <span className="boxesSent">de {data.boxesSent}</span>
                        </> :
                        <span>Sem Dados</span>
                    }
                </div>
            </td>
            <td align="center" className="contentTable">
                <SensitiveBtnContainer onClick={() => handleEditSensitive({ route: element.route, driverName: element.vehicle?.name })}>
                    <span className="sensitiveText">Conferir</span>
                    <div className="imgContainer">
                        <img src={EggIcon} />
                    </div>
                </SensitiveBtnContainer>
            </td>
            <td align="center" className='contentTable'>
                <div className="iconBtns">
                    <ObsBtnContainer onClick={() => handleEdit(element)}>
                        <img src={data.countComment ? ObsNewIcon : ObsIcon} />
                    </ObsBtnContainer>
                    <EditButton onClick={() => handleEditCount(
                        {
                            route: data.route,
                            driverName: data.vehicle?.driver?.name,
                            handleBoxAmount: handleBoxAmount
                        }
                    )} />
                </div>
            </td>
        </Line>
    )
}
