import { LinearProgress } from "@mui/material"
import { IBoxes, IBoxReturn } from "interfaces/IBoxes"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FormContainer } from "./styles"
import CustomDatePicker from "components/DatePicker"
import { boxControlService } from "services/boxControlService"

interface IEditReturnDateForm {
  data: IBoxes,
  setOpen: (x: boolean) => void
}

export const EditReturnDateForm = ({ data, setOpen }: IEditReturnDateForm) => {
  const [boxesReturnHistoryData, setBoxesReturnHistoryData] = useState<IBoxReturn[]>([])
  const [loading, setLoading] = useState(true)

  const handleSave = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await boxControlService.updateBoxReturn(boxesReturnHistoryData)
      toast.success("Datas alteradas com sucesso!")
      setOpen(false)
    } catch (e) {
      toast.error("Houve um erro ao salvar os dados no sistema: " + e)
    } finally {
      setLoading(false)
    }
  }


  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await boxControlService.getBoxReturn(data.id)
      setBoxesReturnHistoryData(response.results);
    } catch (e) {
      toast.error("Houve um erro ao buscar os dados no sistema: " + e)
    } finally {
      setLoading(false)
    }
  }

  const updateData = (updatedElement: IBoxReturn) => {
    setBoxesReturnHistoryData((prevData: IBoxReturn[]) =>
      prevData.map((element: IBoxReturn) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  }

  const formatBoxesReturned = (boxesReturned: number) => {
    return boxesReturned > 0 ? `+${boxesReturned}` : boxesReturned
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <form id="popupForm" autoComplete="false" onSubmit={handleSave}>
      <FormContainer>
        {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
        <div className='cardContainer'>
          <div className="dataCardContainer">
            {boxesReturnHistoryData.sort((a, b) => new Date(a.returnedAt).getTime() - new Date(b.returnedAt).getTime())
              .map((element: IBoxReturn) => (
                <div className="listData">
                  <div className="recordInfo">
                    <span className={element.boxesReturned > 0 ? 'positiveBoxesReturned' : 'negativeBoxesReturned'}>
                      {formatBoxesReturned(element.boxesReturned)} Caixa(s)
                    </span>
                    <span className={'recordId'}>
                      Registro {element.id}
                    </span>
                  </div>
                  <CustomDatePicker updateData={updateData} element={element} />
                </div>
              ))}
          </div>
        </div>
      </FormContainer>
    </form>
  )
}
