
import { useEffect, useState } from "react";
import arrowFilter from 'assets/arrowFilter.svg'
import { SelectTransshipPoint } from './SelectTransshipPoint';
import { api } from 'services/api';
import { baseURL } from 'settings';
import { ITransshipPointFilter } from 'interfaces/IDriver';
import { PaginationProps } from 'interfaces/IPagination';
import { Calendar } from "components/Calendar";
import { FilterSelectContainer, SelectBack } from "./styles";
import CustomCheckBox, { ICheckboxOption } from "components/checkbox";

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void,
  checkboxes?: ICheckboxOption[],
  loading?: boolean
}

export function FilterBoxesReturn({ open, setOpen, onChangeParams, checkboxes, loading }: IProps) {
  const [params, setParams] = useState({ page: 1, pageSize: 300, search: '' })
  const [transshipPointSelect, setTransshipPointSelected] = useState(false)
  const [dateSelect, setDateSelect] = useState(false)
  const [entries, setEntries] = useState<PaginationProps<ITransshipPointFilter>>({} as PaginationProps<ITransshipPointFilter>)

  const fetchData = async () => {
    await api.get(`${baseURL}/v1/delivery/transship-point`, {
      params
    })
      .then((response) => {
        setEntries(response);
      })
  };

  const display = open ? 'initial' : 'none'

  useEffect(() => {
    fetchData()
  }, [params]);

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }}
        style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <div className="list">
          <div className="entry" onClick={() => setTransshipPointSelected(true)}>
            <button type="button" className="entryName">Centro de Distribuição</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
          <div className="entry" onClick={() => setDateSelect(true)}>
            <button type="button" className="entryName">Data</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
          {checkboxes && checkboxes.map((checkbox) => (
            <div className="checkboxes" onClick={checkbox.handleToggle}>
              <button type="button" className="entryName">{checkbox.text}</button>
              <CustomCheckBox
                key={checkbox.id}
                size="small"
                checked={checkbox.state}
                disabled={loading}
              />
            </div>
          ))}
        </div>
        <SelectTransshipPoint
          entries={entries}
          onChangeParams={onChangeParams}
          open={transshipPointSelect}
          setOpen={setTransshipPointSelected}
          loading={loading}
        />
        <Calendar
          isBoxReturn
          dateRangeLimit={14}
          position="right"
          onChangeParams={onChangeParams}
          open={dateSelect}
          setOpen={setDateSelect}
        />
      </FilterSelectContainer>
    </>
  )
}
