import { Button } from "components/Button";
import { Input } from "components/Input";
import { useEffect, useState } from "react";
import { Container, FormContainer, InputContainer } from "./styles";
import goBack from '../../assets/Vector.svg'
import SigninIllust from 'assets/signinIllust.svg'
import Link from "@mui/material/Link";
import { userService } from "services/userService";
import { Checkbox } from "@mui/material";
import permissionsService from "services/permissionsService";
import { IGroup } from "interfaces/IGroup";
import { Logo } from "components/Logo";
import { useFormik } from "formik";
import { signupSchema } from "schemas";
import { Popup } from "components/Popup";
import { InfoPopup } from "components/PopupForms/Info";


export function SignUp() {
  const [showPassword, setShowPassword] = useState(false)
  const [permissions, setPermissions] = useState<(number)[]>([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [adminId, setAdminId] = useState({} as number)
  const [tclgId, setTclgId] = useState({} as number)
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState('')
  const [content, setContent] = useState<JSX.Element>()
  const [infoType, setType] = useState<"info" | "success" | "error">("success")

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, isSubmitting } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: values => {
      if (tclgId !== undefined) {
        setPermissions([...permissions, tclgId])
      }
      const user = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        groups: permissions
      }
      userService.postUser(user)
        .then(data => {
          setContent(
            <form id="popupForm" className="infoForm">
              <div className="section">
                <h2 className="infoTitle">Usuário cadastrado com sucesso!</h2>
                <p><b>Usuário: </b>{data.username || "Sem usuário"}</p>
                <p><b>Senha: </b>{values.password || "Sem senha"}</p>
              </div>
            </form>
          )
          setType('success')
          setOpen(true)
          resetForm()
        })
        .catch((err) => {
          setContent(
            <form id="popupForm" className="infoForm">
              <div className="section">
                <h2 className="infoTitle">Erro ao cadastrar o usuário!</h2>
                <p>Usuário com esse nome já existe no sistema.</p>
              </div>
            </form>
          )
          setType('error')
          setOpen(true)
        })
    },
    validationSchema: signupSchema
  })

  const getGroups = async () => {
    resetForm()
    const perms = await permissionsService.getPermissions()
    perms.forEach((group: IGroup) => {
      if (group.name === 'Admin') {
        setAdminId(group.id)
      }
      if (group.name === 'Tclg') {
        setTclgId(group.id)
      }
    })
  }

  useEffect(() => {
    getGroups()
  }, [])

  return (
    <Container>
      <img src={SigninIllust} alt="" className="illust" />
      <Popup open={open} setOpen={setOpen} keepOnBlur={true} noHeader={true}
        content={
          <InfoPopup open={open} setOpen={setOpen} type={infoType}>
            {content}
          </InfoPopup>
        } />
      <FormContainer>
        <Logo />
        <InputContainer>
          <Link className="backLink" href="/home">
            <img className="goBack" src={goBack} alt="Voltar" /> Voltar
          </Link>
          <h2>Insira seus dados</h2>
          <form className="formWrapper" onSubmit={handleSubmit}>
            <label>Nome</label>
            <Input
              id="firstName"
              className={errors.firstName && touched.firstName ? "error" : ""}
              placeholder="Nome"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName ? errors.firstName : ""}

            />
            <label>Sobrenome</label>
            <Input
              id="lastName"
              className={errors.lastName && touched.lastName ? "error" : ""}
              placeholder="Sobrenome"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName ? errors.lastName : ""}

            />
            <label>Email</label>
            <Input
              id="email"
              className={errors.email && touched.email ? "error" : ""}
              placeholder="Email"
              value={values.email}
              type='email'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email ? errors.email : ""}

            />
            <div className="adminCheck">
              <label>Conta de Administrador</label>
              <Checkbox
                id="admin"
                checked={isAdmin}
                onChange={(evento) => {
                  setIsAdmin(evento.target.checked)
                  if (evento.target.checked) {
                    setPermissions([tclgId, adminId])
                    return
                  }
                  setPermissions([tclgId])
                }}
              />
            </div>
            <div className="line"></div>
            <label>Senha</label>
            <Input
              id="password"
              className={errors.password && touched.password ? "error" : ""}
              placeholder="Digite a senha"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password ? errors.password : ""}
            />
            <label>Confirmar senha</label>
            <Input
              id="confirmPassword"
              className={errors.confirmPassword && touched.password ? "error" : ""}
              placeholder="Digite a senha novamente"
              type={showPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password ? errors.confirmPassword : ""}

            />
            <Button variant="secondary" className="borderButton" text="Criar conta" type="submit" disabled={isSubmitting} />
            <span className="success">{success}</span>
            <span className="errorMsg">{error}</span>
          </form>
        </InputContainer>
      </FormContainer>
    </Container>
  )
}
