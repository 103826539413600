import { Button } from "components/Button"
import { PropsWithChildren } from "react"
import { InfoIcon } from "./InfoIcon"
import { IconContainer, InfoContainer } from "./styles"

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    type: "info" | "error" | "success"
}

export function InfoPopup(props: PropsWithChildren<IProps>) {
    const [open, setOpen] = [props.open, props.setOpen]
    return (
        <InfoContainer>
            <IconContainer>
                <InfoIcon type={props.type} />
            </IconContainer>
            {props.children}
            <Button type="button" text="Fechar" variant="primary" className="closeBtn" onClick={() => setOpen(false)} />
        </InfoContainer>
    )
}
