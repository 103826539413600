import { useEffect, useState } from "react";
import { AddPopupContainer, Back } from "components/Priority/styles";
import { priorityService } from "services/priorityService";
import { IDist } from "interfaces/IDist";
import { toast } from "react-toastify";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function AddCityPopup({ open, setOpen }: IProps) {
    const [depots, setDepots] = useState<IDist[]>([])
    const [state, setState] = useState("")
    const [cityName, setCityName] = useState("")
    const [depotId, setDepotId] = useState(0)

    const handleSave = () => {
        priorityService.saveCity(cityName, state, depotId)
            .then((e) => {
                toast.success("Cidade registrada com sucesso")
                setOpen(false)
            })
            .catch(e => {
                toast.error("Não foi possível registrar a cidade")
            })
    }

    const init = () => {
        priorityService.getDepots()
            .then((response) => {
                setDepots(response.results)
            })
            .catch(e => {
                toast.error("Houve um erro ao buscar os CDs no banco")
            })
    }

    useEffect(() => {
        init()
    }, [])

    if (!open) return null

    return (
        <>
            <Back onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <AddPopupContainer>
                <input type="text" className="text" placeholder="Nome da cidade" onChange={(e) => setCityName(e.target.value)} />
                <select name="state" className="state" defaultValue="" onChange={(e) => setState(e.target.value)} >
                    <option disabled hidden value="">Estado</option>
                    <option value="SP" className="stateOption">SP</option>
                </select>
                <select name="depot" className="depot" defaultValue="" onChange={(e) => setDepotId(parseInt(e.target.value))}>
                    <option disabled hidden value="">CD</option>
                    {depots.sort((a, b) => a.name.localeCompare(b.name)).map(depot => (
                        <option value={depot.id} className="depotOption" key={depot.id}>{depot.name}</option>
                    ))}
                </select>
                <button className="saveBtn" onClick={handleSave} disabled={!depotId || !cityName || !state}>Salvar</button>
            </AddPopupContainer>

        </>
    )
}
