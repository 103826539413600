import ReceiptIcon from '@mui/icons-material/Receipt';
import { EditButton } from "components/EditButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ITeams } from "interfaces/ITeams";

interface IProps {
    data: ITeams,
    handleEdit: () => void,
    routeLabel?: string,
    routeError?: boolean,
}

export function TeamCard({ data, routeLabel, handleEdit, routeError }: IProps) {
    const [teamData, setData] = useState<ITeams>(data)

    useEffect(() => {
        setData(data)
    }, [])

    return (
        <>
            <div className="mainInfo">
                <p className="name">{data?.name || "Sem Nome"}</p>
                {routeLabel && <p className="route">{routeLabel}</p>}
            </div>
            <div className="sideMenu">
                <div className="infoTag">
                </div>
                <button className="receiptBtn" onClick={() => toast.warn("Função ainda em desenvolvimento.")}>
                    <ReceiptIcon sx={{ fill: "#757575" }} />
                </button>
                <EditButton onClick={handleEdit} />
                <span className="driverAmount">{data.members.length} Motoristas</span>
            </div>
        </>
    );
}
