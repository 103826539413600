import { ICity } from "interfaces/ICity"
import { PriorityContainer } from "../styles"
import SearchIcon from 'assets/searchIcon.svg'
import { Input } from "components/Input"
import { CityLine } from "./CityLine"
import { useEffect, useState } from "react"
import { api } from "services/api"
import { baseURL } from "settings"
import { useDebaunce } from "utils/debaunce"
import { priorityService } from "services/priorityService"
import { toast } from "react-toastify"
import { LinearProgress } from "@mui/material"
import { AddCityPopup } from "./AddCity"

export function CityPriority() {
    const [params, setParams] = useState({ page: 0, search: '', notPaginated: true })
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [data, setData] = useState<{ [index: string]: ICity[] }>({})
    const [changedCities, setChangedCities] = useState<ICity[]>([])

    const fetchData = () => {
        setLoading(true)
        api.get(`${baseURL}/v1/delivery/delivery-city`, { params })
            .then((response) => {
                joinData(response.results)
            }).catch(e => {
                toast.error("Houve um erro ao contatar o banco de dados")
            }).finally(() => {
                setLoading(false)
            })
    }

    function onChangeParams(params: any) {
        setParams((state: any) => ({ ...state, ...params }))
    }

    const handleSave = () => {
        priorityService.updatePriorities(changedCities)
            .then((e) => {
                toast.success("Prioridades atualizadas com sucesso!")
                setChangedCities([])
                fetchData()
            }).catch((e) => {
                toast.error("Não foi possível salvar as prioridades")
            }).finally(() => {
                setCanEdit(false)
            })
    }

    const handleUpdate = (element: ICity, remove: boolean) => {
        const updatedCities = [...changedCities]
        const index = updatedCities.findIndex(city => city.id === element.id)

        if (index !== -1) {
            updatedCities[index] = element
        } else {
            updatedCities.push(element)
        }
        setChangedCities(remove ? updatedCities.filter(city => city.id !== element.id) : updatedCities)
    }

    const handleCancel = () => {
        setCanEdit(false)
        setChangedCities([])
    }

    const joinData = (data: ICity[]) => {
        let cityList: { [index: string]: ICity[] } = {}

        cityList = data.reduce((acc, city) => {
            if (city.priority && !acc[city.priority]) {
                acc[city.priority] = []
            }
            city.priority && acc[city.priority].push(city)
            return acc
        }, cityList)
        setData(cityList)
    }

    useEffect(() => {
        fetchData()
    }, [params])

    return (
        <PriorityContainer className="wide">
            <div className="header">
                <div className="left" style={{ width: "140%" }}>
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input className="inputBox" placeholder="Nome ou ID"
                            onChange={e => debaunceFilter({ search: e.target.value, page: 1 })} />
                    </div>
                    <div className="addBtnContainer">
                        <button className="addBtn" onClick={() => setOpen(true)}>+</button>
                    </div>
                </div>
                <div className="right">
                    <div className="editBtns">
                        {canEdit &&
                            <button className="cancelBtn" onClick={handleCancel}>Cancelar</button>
                        }
                        <button className="editBtn"
                            onClick={() => canEdit ? handleSave() : setCanEdit(true)}
                            disabled={canEdit && changedCities.length < 1}>
                            <span>{canEdit ? "Salvar" : "Editar"}</span>
                        </button>
                    </div>
                </div>
                {open && <AddCityPopup open={open} setOpen={setOpen} />}
            </div>
            <div className="main">
                <div className="columns">
                    <span className="small">ID</span>
                    <span className="large">Nome</span>
                    <span className="small">UF</span>
                    <span className="small">CD ID</span>
                    <span className="medium">Prioridade</span>
                </div>
                {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
                <div className="list">
                    {Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]))
                        .map((entry) => (
                            <div className="priorityGroup" key={entry[0]}>
                                <div className="side">
                                    <span className="priorityLetter">{entry[0]}</span>
                                </div>
                                <div className="entries">
                                    {entry[1].sort((a, b) => a.name.localeCompare(b.name)).map((element) => (
                                        <CityLine data={element} handleUpdate={handleUpdate} canEdit={canEdit} key={element.id} />
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </PriorityContainer>
    )
}
