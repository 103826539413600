import { Input } from "components/Input"
import { useFormik } from "formik"
import { registerSchema } from "schemas"
import { driverVehicleService } from "services/driverVehicleService"

import { unMask, mask } from "remask"
import { IDriver } from "interfaces/IDriver"
import { IDist } from "interfaces/IDist"
import { useState } from "react"
import { DropdownButton } from "components/DropdownButton"
import { defaultTheme } from "styles/theme/default"
import { DistSelect } from "../DriverForm/DistSelect"
import { ModelSelect } from "../DriverForm/ModelSelect"

export const RegisterForm = () => {
    const [model, setModel] = useState("")
    const [dist, setDist] = useState<IDist>({} as IDist)
    const [openDist, setOpenDist] = useState(false)
    const [openModel, setOpenModel] = useState(false)
    const phonePatterns = ['(99) 9999-9999', '(99) 9 9999-9999']
    const agileCodePattern = ['999.999.999-9', '999.999.999-99']
    const platePatterns = ['AAA-9999', 'AAA-9A99']

    const formik = useFormik({
        initialValues: {
            driverName: "",
            vehicleName: "",
            agileCode: "",
            agilePass: "",
            agileConfirm: "",
            licensePlate: "",
            capacity: 0,
            phone: "",
            color: ""
        },
        onSubmit: async (values) => {
            const nameArray = values.driverName.trim().split(" ")
            const driverParams = {
                name: nameArray[0].trim(),
                surname: nameArray.slice(1).join(" ").trim(),
                phoneNumber: unMask(values.phone),
                agilePassword: values.agilePass,
                agileCode: unMask(values.agileCode),
            }
            const vehicleParams = {
                name: values.vehicleName.trim(),
                licensePlate: values.licensePlate,
                distributionCenter: dist.name,
                transshipId: dist.id,
                capacity: values.capacity,
                model: model,
                agileUniqueCode: unMask(values.agileCode),
                color: values.color
            }
            const newDriver: IDriver = await driverVehicleService.registerDriver(driverParams)

            const newParams = { ...vehicleParams, driverId: newDriver.id }
            await driverVehicleService.registerVehicle(newParams)
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: registerSchema
    })
    const { handleChange, handleSubmit, handleBlur, errors, values, setFieldValue } = formik

    const handleSelectDist = (element: IDist) => {
        setDist(element)
    }

    const handleSelectModel = (element: string) => {
        setModel(element)
    }


    return (
        <form id="popupForm" onSubmit={handleSubmit}>
            <div className="col1">
                <div className="section">
                    <h2 className="sectionTitle">Dados do Motorista</h2>
                    <div className="form">
                        <label htmlFor="driverName" className="formName">Nome</label>
                        <Input type="text" name="driverName" className="bigInput"
                            onChange={(e) => {
                                setFieldValue('vehicleName', values.driverName)
                                handleChange(e)
                            }}
                            onBlur={handleBlur} error={errors.driverName} value={values.driverName} />
                    </div>
                    <div className="form">
                        <label htmlFor="phone" className="formName">Tel/Cel</label>
                        <Input type="text" name="phone" className="medInput"
                            onChange={(e) => {
                                let originalValue = unMask(e.target.value)
                                e.target.value = mask(originalValue, phonePatterns)
                                handleChange(e)
                            }}
                            onBlur={handleBlur} error={errors.phone} value={values.phone} />
                    </div>
                </div>
                <div className="section">
                    <h2 className="sectionTitle">Dados do Agile</h2>
                    <div className="form">
                        <label htmlFor="agileCode" className="formName">CPF Motorista</label>
                        <Input type="text" name="agileCode" className="bigInput"
                            onChange={(e) => {
                                let originalValue = unMask(e.target.value)
                                e.target.value = mask(originalValue, agileCodePattern)
                                handleChange(e)
                            }}
                            onBlur={handleBlur} error={errors.agileCode} value={values.agileCode} />
                    </div>
                    <div className="form">
                        <label htmlFor="agilePass" className="formName">Senha Agile</label>
                        <Input type="password" name="agilePass" className="bigInput" onChange={handleChange} onBlur={handleBlur} error={errors.agilePass} value={values.agilePass} />
                    </div>
                    <div className="form">
                        <label htmlFor="agileConfirm" className="formName">Confirmar Senha</label>
                        <Input type="password" name="agileConfirm" className="bigInput" onChange={handleChange} onBlur={handleBlur} error={errors.agileConfirm} value={values.agileConfirm} />
                    </div>

                </div>
            </div>
            <div className="col2">
                <div className="section">
                    <h2 className="sectionTitle">Informações do Veículo</h2>
                    <div className="form">
                        <label htmlFor="vehicleName" className="formName">Nome</label>
                        <Input type="text" name="vehicleName" className="bigInput"
                            onChange={handleChange} onBlur={handleBlur} error={errors.vehicleName} value={values.driverName} readOnly />
                    </div>
                    <div className="form">
                        <label htmlFor="model" className="formName">Centro Dist.</label>
                        <DropdownButton
                            className="medium"
                            text={dist.name || "Sem CD"}
                            bgColor={defaultTheme.colors['base-input']}
                            content={<DistSelect open={openDist} setOpen={setOpenDist} handleSelect={handleSelectDist} />}
                            select={openDist}
                            setSelect={setOpenDist} />
                    </div>
                    <div className="form">
                        <label htmlFor="model" className="formName">Modelo</label>
                        <DropdownButton
                            text={model}
                            bgColor={defaultTheme.colors['base-input']}
                            content={<ModelSelect open={openModel} setOpen={setOpenModel} handleSelect={handleSelectModel} />}
                            select={openModel}
                            setSelect={setOpenModel} />
                    </div>
                    <div className="form">
                        <label htmlFor="licensePlate" className="formName">Placa</label>
                        <Input type="text" name="licensePlate" className="medInput"
                            onChange={(e) => {
                                let originalValue = e.target.value
                                e.target.value = originalValue.toLocaleUpperCase()
                                handleChange(e)
                            }}
                            onBlur={handleBlur} error={errors.licensePlate} value={values.licensePlate} />
                    </div>
                    <div className="form">
                        <label htmlFor="capacity" className="formName">Capacidade</label>
                        <Input type="number" name="capacity" className="smallInput" onChange={handleChange} onBlur={handleBlur} error={errors.capacity} value={values.capacity} />
                    </div>
                </div>
            </div>
        </form>
    )
}
