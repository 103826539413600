import { ColumnProps, DataTable } from "components/Table";
import { useEffect, useState } from "react";
import { PaginationProps } from "interfaces/IPagination";
import { IBoxes } from 'interfaces/IBoxes';
import { FormTableLine } from './FormTable';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { DriverNameStyles } from "./styles";
import CustomCheckBox from "components/checkbox";
import { dateToday } from "utils/dateToday";
import { boxControlService } from "services/boxControlService";

const defaultColumns: ColumnProps[] = [
  {
    name: "Data",
    align: "center",
  },
  {
    name: "Rota",
    align: "center",
  },
  {
    name: "Saldo",
    align: "center",
  },
]

const smallScreenColumns: ColumnProps[] = [
  {
    name: "Data",
    align: "center",
  },
  {
    name: "Rota",
    align: "center",
  },
  {
    name: "Saldo",
    align: "center",
  },
]

export interface BoxHistoryFormProps {
  vehicleId: string;
}

export function BoxHistoryForm({ vehicleId }: BoxHistoryFormProps) {
  const smallScreen = useMediaQuery({ maxWidth: 820 })
  const [dataPagination, setDataPagination] = useState<PaginationProps<IBoxes>>({} as PaginationProps<IBoxes>)
  const [loading, setLoading] = useState(true)
  const [showNegativeBalanceOnly, setshowNegativeBalanceOnly] = useState(false);
  let columns = smallScreen ? smallScreenColumns : defaultColumns

  const handleToggleFilter = () => {
    setshowNegativeBalanceOnly(!showNegativeBalanceOnly);
  };

  const fetchData = async () => {
      try {
        const params = {
          startDeliveryDate: dateToday(-600),
          endDeliveryDate: dateToday(-1),
          vehicleId: vehicleId,
          boxesSentNotNull: true
        };

        const response = await boxControlService.getBoxesSent(params);

        setLoading(true)
        setDataPagination(response)
      } catch(error) {
        toast.error("Erro ao buscar dados no sistema")
      } finally {
        setLoading(false)
      }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <h3 style={DriverNameStyles}>
        {dataPagination && dataPagination.results && dataPagination.results.length > 0
          && dataPagination.results[0]?.vehicle?.driver?.name}
      </h3>
      <CustomCheckBox handleToggle={handleToggleFilter} text={"Mostrar Somente Valores Negativos"} />
      <DataTable loading={loading} columns={columns} hasBorderRadius={false} isPopup={true}>
        {dataPagination?.results &&
          dataPagination.results
            .filter((element) => {
              const value = element.boxesSent - element.boxesReturned
              return (!showNegativeBalanceOnly || value > 0) && value !== element.boxesSent
            })
            .sort(
              (a: IBoxes, b: IBoxes) =>
                new Date(b.deliveryDate).getTime() -
                new Date(a.deliveryDate).getTime()
            )
            .map((element) => (
              <FormTableLine element={element} key={element.id} />
            ))}
      </DataTable>
    </div>
  );
}
