import SearchIcon from 'assets/searchIcon.svg'
import { RouteSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { IWaybill } from 'interfaces/IWaybill';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    onChangeParams: (x: any) => void,
    entries: PaginationProps<IWaybill>
}

export function RouteSelect({ open, setOpen, onChangeParams, entries }: IProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRoutes, setSelectedRoutes] = useState<number[]>([])

    const handleCheckbox = async (element: number) => {
        if (selectedRoutes?.includes(element)) {
            setSelectedRoutes(state => ([...state?.filter((data: number) => data !== element)]));
        } else {
            setSelectedRoutes(state => ([...state, element]));
        }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
    };

    useEffect(() => {
        onChangeParams({ "route": selectedRoutes.join(","), "page": 1 })
    }, [selectedRoutes]);

    if (!open) return null

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <RouteSelectContainer>
                <div className="header">
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input
                            className="inputBox"
                            onChange={handleSearch}
                            placeholder="Rota"
                        />
                    </div>
                </div>
                <div className="list">
                    {entries?.results.filter(item => item?.route?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(element => (
                            <div className="entry" key={element.id} onClick={() => handleCheckbox(element.route)}>
                                <button type="button" className="entryName" key={element.id} >{`${element.route}`}</button>
                                <input
                                    type="checkbox"
                                    checked={selectedRoutes?.includes(element?.route)}
                                />
                            </div>
                        ))}
                </div>
            </RouteSelectContainer>
        </>
    )
}
