import { Input } from "components/Input";
import { useFormik } from "formik";
import { ITeams } from "interfaces/ITeams";
import { useEffect, useState } from "react";
import * as yup from "yup"

import { DropdownButton } from "components/DropdownButton";
import { DriverList } from "./DriverList";
import { IDriver } from "interfaces/IDriver";
import { driverVehicleService } from "services/driverVehicleService";
import { toast } from "react-toastify";
import { ColorSelect } from "./ColorSelect";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DistSelect } from "../DriverForm/DistSelect";
import { defaultTheme } from "styles/theme/default";
import { IDist } from "interfaces/IDist";

interface IProps {
    data: ITeams,
    handleSave: (data: ITeams, isNew: boolean) => void
}

export function TeamForm({ data, handleSave }: IProps) {
    const [team, setTeam] = useState<ITeams>(data ?? {} as ITeams)
    const [select, setSelect] = useState(false)
    const [members, setMembers] = useState<IDriver[]>(data.members)
    const [leaders, setLeaders] = useState<IDriver[]>([] as IDriver[])
    const [driverAmount, setAmount] = useState(members.length)
    const [color, setColor] = useState(data?.colorTeam || "#aaa")
    const [colorSelector, setColorSelector] = useState(false)
    const [transship, setTransship] = useState<IDist>(data.transshipPoint)

    const nameRegexp = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi
    const schema = yup.object().shape({
        name: yup.string().matches(nameRegexp, "Digite um nome válido!").required("Digite um nome")
    })

    const { values, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: data?.name || ""
        },
        onSubmit: async () => {
            if (members.length < 2) {
                toast.error("Não é possível ter um time com menos de dois membros!")
                return
            }

            let leaderAmount = leaders.length

            if (leaderAmount > 1) {
                toast.error("Não é possível ter um time com mais de um líder!")
                return
            } else if (leaderAmount < 1 || !leaders[0].id) {
                toast.error("Não é possível ter um time sem líderes!")
                return
            }

            // Check if leader belongs to team
            const isMember = (leaders[0].teamId || leaders[0].team) === data.id

            if (!isMember) {
                toast.error("O líder deve pertencer ao time!")
                return
            }

            let newTeam = team
            newTeam.name = leaders[0].name
            setFieldValue('name', leaders[0].name)
            if (newTeam.leader?.leader) {
                newTeam.leader.leader.id = leaders[0]?.id || newTeam.leader?.leader?.id;
            }

            newTeam.transshipPoint = transship

            toast.info("Aguarde...")

            try {
                for (const driver of members) {
                    await driverVehicleService.updateDriver(driver)
                }
            } catch (e) {
                toast.error("Houve um erro ao salvar os motoristas")
                return
            }

            handleSave(newTeam, data ? false : true)
        },
        validationSchema: schema,
        validateOnChange: true
    })

    const handleSelectDriver = (element: IDriver, state: boolean, leader?: boolean) => {
        const index = members.findIndex(member => member.id === element.id)
        let updatedMembers: IDriver[] = [...members]
        let leaderList = leaders

        if (index !== -1) {
            // The element already exists in the members array, so update it.
            element.isLeader = leader
            element.teamId = state ? team.id || element.teamId : "null"
            updatedMembers[index] = element
            setMembers(updatedMembers)
        } else {
            // The element doesn't exist in the members array, so add it.
            setMembers([...members, element])
        }
        leaderList = updatedMembers.filter(driver => driver.isLeader)

        setLeaders(leaderList)
        setTeam({ ...team, members: updatedMembers })
    }

    useEffect(() => {
        let leader = data.members.find(driver => driver.id === data.leader?.leader?.id)
        leader && setLeaders([...leaders, leader])
    }, [])

    useEffect(() => {
        setAmount(members.filter((driver) => driver.teamId !== "null").length)
    }, [members])

    useEffect(() => {
        setTeam({ ...team, colorTeam: color })
    }, [color])

    const handleSelectDepot = (element: IDist) => {
        setTransship(element)
    }

    return (
        <form id="popupForm" className="teamForm" onSubmit={handleSubmit}>
            <div className="col1">
                <div className="section horizontal">
                    <div className="form vertical changeColor">
                        <label htmlFor="color" className="formName">Cor</label>
                        <div className="buttonContainer" style={{ display: "flex", cursor: "pointer" }} onClick={() => setColorSelector(true)}>
                            <button name="color" type="button" className="changeColorBtn" style={{ backgroundColor: color }} />
                            <KeyboardArrowDown style={{ pointerEvents: "none" }} />
                        </div>
                        <ColorSelect handleSelect={setColor} open={colorSelector} setOpen={setColorSelector} />
                    </div>
                    <div className="form vertical">
                        <label htmlFor="name" className="formName">Nome do Time</label>
                        <Input name="name" className="teamInput" onChange={handleChange} onBlur={handleBlur} value={values.name || "Sem Nome"} readOnly />
                    </div>
                    <div className="form vertical">
                        <label htmlFor="route" className="formName">Região</label>
                        <DropdownButton
                            className="large"
                            text={transship?.name || "Selecione uma região"}
                            bgColor={defaultTheme.colors['base-light']}
                            select={select}
                            setSelect={setSelect}
                            content={<DistSelect open={select} setOpen={setSelect} handleSelect={handleSelectDepot} />} />
                    </div>
                </div>
                <div className="section">
                    <div className="form full">
                        <label className="formName">Motoristas</label>
                        <span className="drivers">{`${driverAmount || 0} motoristas`}</span>
                    </div>
                    <div className="form">
                        <DriverList data={team} handleSelect={handleSelectDriver} className="driverList" />
                    </div>
                </div>
            </div>
        </form>
    )
}
