import SidebarComponent from 'components/Sidebar';
import { Header } from 'components/Header';
import { Container, MainContainer, SidebarContainer } from './styles';
import { ColumnProps, DataTable } from "components/Table";
import { baseURL } from "settings";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { PaginationProps } from "interfaces/IPagination";
import { PageContainer } from 'components/PageContainer'
import { Toolbar } from "components/Toolbar";
import { Popup } from "components/Popup";
import { ObservationForm } from "components/PopupForms/ObservationForm";
import { BtnLock, IBoxCountData, IBoxes, ISensitiveData } from 'interfaces/IBoxes';
import { FormTableLine } from './FormTable';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { dateToday } from 'utils/dateToday';
import SettingsIcon from '@mui/icons-material/Settings';
import { BoxDivergenceForm } from 'components/PopupForms/BoxDivergenceForm';
import { boxControlService } from 'services/boxControlService';
import { SensitiveItemsForm } from 'components/PopupForms/SensitiveItemsForm';
import { ProductConfigForm } from 'components/PopupForms/ProductConfigForm';

const defaultColumns: ColumnProps[] = [
  {
    name: "Nome",
    align: "left",
  },
  {
    name: "Caixas que Entraram",
    align: "center",
  },
  {
    name: "Itens Sensíveis"
  },
  {
    name: "Obs.",
    align: "center",
  },
]

const smallScreenColumns: ColumnProps[] = [
  {
    name: "Nome",
    align: "left",
  },
  {
    name: "Caixas",
    align: "center",
  },
  {
    name: "Quebras"
  },
  {
    name: "Obs.",
    align: "center",
  },
]

export function BoxCount() {
  const smallScreen = useMediaQuery({ maxWidth: 820 })
  const [popupVisible, setPopupVisible] = useState(false)
  const [countPopupVisible, setCountPopupVisible] = useState(false)
  const [sensitivePopupVisible, setSensitivePopupVisible] = useState(false)
  const [productPopupVisible, setProductPopupVisible] = useState(false)
  const [formEdit, setFormEdit] = useState<IBoxes>({} as IBoxes)
  const [boxCountEdit, setBoxCountEdit] = useState<IBoxCountData>({} as IBoxCountData)
  const [sensitiveEdit, setSensitiveEdit] = useState<ISensitiveData>({} as ISensitiveData)
  const [searchTerm, setSearchTerm] = useState('')
  const [dataPagination, setDataPagination] = useState<PaginationProps<IBoxes>>({} as PaginationProps<IBoxes>)
  const [params, setParams] = useState({ search: '', startDeliveryDate: dateToday(), endDeliveryDate: dateToday() })
  const [loading, setLoading] = useState(true)
  const [popupBtnLock, setPopupBtnLock] = useState<BtnLock>({ obsBtn: false, boxBtn: false, sensitiveBtn: false, productBtn: false })
  const [toggleRoute, setToggle] = useState(false)
  let columns = smallScreen ? smallScreenColumns : defaultColumns
  columns[0].name = toggleRoute ? "Rota" : "Nome"

  const submitForm = (element: IBoxes) => {
    boxControlService.submitForm(element)
      .then(e => {
        toast.success("Dados enviados com sucesso!")
      }).catch(e => {
        toast.error("Erro ao submeter os dados")
      })
  }
  
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await boxControlService.getBoxesSent(params);
      setDataPagination(response)
    } catch(error) {
      toast.error("Erro ao buscar dados no sistema")
    } finally {
      setLoading(false)
    }
}

  const handleEdit = (data: IBoxes) => {
    setFormEdit(data)
    setPopupVisible(true)
  }

  const handleEditCount = (data: IBoxCountData) => {
    setBoxCountEdit({
      route: data.route,
      driverName: data.driverName,
      handleBoxAmount: data.handleBoxAmount
    })
    setCountPopupVisible(true)
  }

  const handleEditSensitiveItems = (data: ISensitiveData) => {
    setSensitiveEdit(data)
    setSensitivePopupVisible(true)
  }

  const handleSaveComment = (comment: string) => {
    const newElement = { ...formEdit, countComment: comment }
    boxControlService.updateComment(newElement)
      .then(e => {
        toast.success("Observação salva com sucesso!")
        fetchData()
      }).catch(e => {
        toast.error("Não foi possível salvar a observação")
      })
  }

  useEffect(() => {
    fetchData()
  }, [params])

  return (
    <Container>
      <Popup
        popupTitle='Editar Observação'
        open={popupVisible}
        setOpen={setPopupVisible}
        isEditPopup={true}
        isObservation={true}
        disableSave={popupBtnLock.obsBtn}
        content={
          <ObservationForm
            data={formEdit}
            handleSave={handleSaveComment}
            isCount />
        } />
      <Popup
        popupTitle='Contagem de Caixas'
        open={countPopupVisible}
        setOpen={setCountPopupVisible}
        isEditPopup={true}
        disableSave={popupBtnLock.boxBtn}
        content={
          <BoxDivergenceForm
            setOpen={setCountPopupVisible}
            route={boxCountEdit.route}
            handleBoxAmount={boxCountEdit.handleBoxAmount}
            isCount />
        }
        keepOnBlur />
      <Popup
        popupTitle='Ítens Sensíveis'
        open={sensitivePopupVisible}
        setOpen={setSensitivePopupVisible}
        disableSave={popupBtnLock.obsBtn}
        content={
          <SensitiveItemsForm
            route={sensitiveEdit.route}
            driverName={sensitiveEdit.driverName}
            setDisableSave={setPopupBtnLock}
            setOpen={setSensitivePopupVisible} />
        }
        aside
        keepOnBlur
        isEditPopup
      />
      <Popup
        popupTitle='Editar Itens Sensíveis'
        open={productPopupVisible}
        setOpen={setProductPopupVisible}
        disableSave={popupBtnLock.productBtn}
        content={<ProductConfigForm setOpen={setProductPopupVisible} />}
        aside={smallScreen}
        isEditPopup
      />
      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>

        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Entrada de Caixas</h3>
              <Toolbar
                params={params}
                paramState={setParams}
                loadingState={loading}
                placeholder="Motorista, veículo ou rota"
                page='BoxCount'
                import={true}
                extraButtons={[
                  {
                    type: "outlined",
                    text: "Editar itens sensíveis",
                    onClick: () => setProductPopupVisible(true),
                    customWidth: "200px",
                    icon: <SettingsIcon />
                  }
                ]}
              />
            </div>
            <div className="tableContainer">
              <DataTable loading={loading} columns={columns}>
                {dataPagination?.results?.filter(item => item?.id?.toString().includes(searchTerm.trim()))
                  .sort((a: IBoxes, b: IBoxes) => a.vehicle.name.localeCompare(b.vehicle.name))
                  .map((element) => (
                    <FormTableLine toggle={toggleRoute} key={element.id} element={element}
                      onSubmit={submitForm} handleEdit={handleEdit} handleEditCount={handleEditCount} handleEditSensitive={handleEditSensitiveItems} />
                  ))}
              </DataTable >
            </div>
            <div className="spacer" />
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}
