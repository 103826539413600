import { useEffect, useState } from "react";
import { Container, MallFilterContainer, StatusFilterContainer } from "./styles";
import { defaultTheme } from "styles/theme/default";
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

interface Props {
  onChangeParams: (x: any) => void;
}

export function DeliveryStatusFilter({ onChangeParams }: Props) {
  const [selectedFilter, setSelectedFilter] = useState<any>("");

  const handleStatusClick = (status: string) => {
    if (status === selectedFilter) {
      setSelectedFilter("");
    } else {
      setSelectedFilter(status);
    }
  };

  useEffect(() => {
    if (selectedFilter === "mall") {
      onChangeParams({ onlyMallOrders: true });
      return;
    }

    onChangeParams({ deliveryStatus: selectedFilter, onlyMallOrders: false });
  }, [selectedFilter]);

  return (
    <Container>
      <StatusFilterContainer>
        <button
          className={`statusDelivery ${selectedFilter === "falha" ? "active" : ""}`}
          onClick={() => handleStatusClick("falha")}
        >
          <div className={`statusBall ${selectedFilter === "falha" ? "active" : ""}`}></div>
          Falha
        </button>
        <button
          className={`statusDelivery ${selectedFilter === "pendente" ? "active" : ""}`}
          onClick={() => handleStatusClick("pendente")}
        >
          <div className={`statusBallBlue ${selectedFilter === "pendente" ? "active" : ""}`}></div>
          Em trânsito
        </button>
        <button
          className={`statusDelivery ${selectedFilter === "sucesso" ? "active" : ""}`}
          onClick={() => handleStatusClick("sucesso")}
        >
          <div className={`statusBallGreen ${selectedFilter === "sucesso" ? "active" : ""}`}></div>
          Entregue
        </button>
      </StatusFilterContainer>
      <MallFilterContainer>
        <button
          className={`mallFilter ${selectedFilter === "mall" ? "active" : ""}`}
          onClick={() => handleStatusClick("mall")}
        >
          <StoreMallDirectoryIcon sx={{ fill: defaultTheme.colors['logo-yellow'], fontSize: 21 }} />
          Shoppings
        </button>
      </MallFilterContainer>
    </Container>
  );
}
