import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, MainContainer, SidebarContainer } from '../styles';
import { useEffect, useState } from "react";
import { baseURL } from "settings";
import { api } from "services/api";
import { PaginationProps } from "interfaces/IPagination";
import Pagination from '@mui/material/Pagination'
import { useDebaunce } from "utils/debaunce";
import { PageContainer } from "components/PageContainer";
import { Popup } from "components/Popup";
import { Toolbar } from "components/Toolbar";
import { DataCard } from 'components/DataCard';
import { ITeams } from 'interfaces/ITeams';
import { TeamCard } from 'components/DataCard/TeamCard';
import { TeamForm } from 'components/PopupForms/TeamForm';
import { teamService } from 'services/teamService';
import { RegisterTeam } from 'components/PopupForms/TeamForm/Register';
import { TabBar } from 'components/TabBar';
import { LinearProgress } from '@mui/material';

export function Teams() {
  const [teams, setTeams] = useState<PaginationProps<ITeams>>({} as PaginationProps<ITeams>)
  const [formData, setFormData] = useState<ITeams>({} as ITeams)
  const [isPost, setPost] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false);
  const [params, setParams] = useState({ page: 1, pageSize: 6, search: '' })
  const [loading, setLoading] = useState(false);
  const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })

  const fetchData = () => {
    setLoading(true)
    api.get(`${baseURL}/v1/delivery/team`, {
      params
    })
      .then((response) => {
        setTeams(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function onChangeParams(params: any) {
    setParams(state => ({ ...state, ...params }))
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    debaunceFilter({ page })
  }

  const handleAdd = () => {
    setPost(true)
    setFormData({} as ITeams)
    setPopupVisible(true)
  }

  const handleEdit = (element: ITeams) => {
    setPost(false)
    setPopupVisible(true)
    setFormData(element)
  }

  const handleSave = (data: ITeams, isNew: boolean) => {
    isNew ? teamService.registerTeam(data) : teamService.updateTeam(data)
  }

  useEffect(() => {
    fetchData()
  }, [params, popupVisible]);

  return (
    <Container>
      <Popup
        popupTitle={isPost ? "Criar Time" : "Editar Time"}
        open={popupVisible}
        canDelete={isPost ? false : true}
        onDelete={() => teamService.deleteTeam(formData)}
        isEditPopup={true}
        setOpen={setPopupVisible}
        content={isPost ? <RegisterTeam handleSave={handleSave} /> : <TeamForm handleSave={handleSave} data={formData} />}
      />
      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Motoristas e Times</h3>
              <TabBar
                tabs={[
                  { label: "Motoristas", path: "/drivers" },
                  { label: "Times", path: "/teams" }
                ]}
                selectedPage="/teams"
              />
              <Toolbar
                params={params}
                paramState={setParams}
                loadingState={loading}
                placeholder="Nome do Time"
                add={true}
                onAdd={handleAdd}
                page='TeamPage'
              />
            </div>
            <div className="dataCardContainer">
              {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
              {teams.results?.filter(item => item?.name?.toLowerCase().includes(params.search.toLowerCase()))
                .sort((a: ITeams, b: ITeams) => a.name.localeCompare(b.name))
                .map(element => (
                  <DataCard key={element.id} stripColor={element.colorTeam} stripWidth='12px' type="teamCard">
                    <TeamCard data={element} handleEdit={() => handleEdit(element)} key={element.id} routeLabel={element.transshipPoint?.name || "Sem CD"} />
                  </DataCard>
                ))}
            </div>
            <Pagination
              shape="rounded"
              count={teams?.totalPages}
              page={params.page}
              onChange={handlePageChange}
              className='pagination'
            />
          </MainContainer>
        </PageContainer>
      </div>

    </Container>
  )
}
