import { CircularProgress } from "@mui/material";
import { Button, ButtonPlanilha } from "components/Button";
import { useRef, useState } from "react";
import { useDebaunce } from "utils/debaunce";
import PlusIcon from "assets/iconPlusButtonAdd.svg";
import PlanilhaIcon from "assets/planilhaIcon.svg";
import PDFIcon from "assets/pdfIcon.svg";
import { ExtraButtonContainer, ToolbarWrapper } from "./styles";
import { toast } from "react-toastify";
import { deliveryService } from "services/deliveryService";
import { FilterDeliveryStatus } from "components/FilterDeliveries";
import { FilterDrivers } from "components/FilterDrivers";
import { Calendar } from "components/Calendar";
import { FilterDeliveries } from "components/FilterDeliveriesPage";
import InputFilter from "components/Toolbar/InputFilter";
import ButtonFilter from "./ButtonFilter";
import ButtonModalOverRoutesBoxes from "./ButtonModalOverRoutesBoxes";
import { FilterBoxesReturn } from "components/FilterBoxesReturn";
import { ICheckboxOption } from "components/checkbox";
import { FilterRoutes } from "components/FilterRoutes";

type ToolbarButton = {
  type: "filled" | "outlined";
  text: string;
  onClick: (x?: any, y?: any) => any;
  disableState?: boolean;
  customWidth?: string;
  icon?: JSX.Element;
  customMargin?: string;
};

interface ToolbarProps {
  params?: {
    page?: number;
    pageSize?: number;
    search?: string;
    deliveryDate?: string;
  };
  minSearchSize?: number;
  paramsDate?: {};
  paramsDateState?: any;
  paramsTeam?: {};
  paramsTeamState?: any;
  paramState?: any;
  loadingState: boolean;
  placeholder?: string;
  add?: boolean;
  genericAdd?: boolean;
  addText?: string;
  export?: boolean;
  import?: boolean;
  onAdd?: () => void;
  onDownload?: () => void;
  onImport?: () => void;
  page?: string;
  extraButtons?: ToolbarButton[];
  hideFilter?: boolean;
  hideSearch?: boolean;
  singleButton?: boolean;
  checkboxes?: ICheckboxOption[];
}

export function Toolbar(props: ToolbarProps) {
  const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 });
  const [params, setParams] = [props.params, props.paramState];
  const [paramsDate, setParamsDate] = [props.paramsDate, props.paramsDateState];
  const [paramsTeam, setParamsTeam] = [props.paramsTeam, props.paramsTeamState];
  const loading = props.loadingState;
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filterSelect, setFilterSelect] = useState(false);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      handleSubmit(event.target.files[0]);
    }
  };

  const handleSubmit = async (file: File) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("arquivo", file);
      await deliveryService.uploadRouteFile(formData);
      toast.success("Arquivo enviado com sucesso!");
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao enviar o arquivo. Por favor, tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  function onChangeParams(params: any) {
    if (props.loadingState) return;
    setParams((state: any) => ({ ...state, ...params }));
  }

  function onChangeParamsDate(params: any) {
    setParamsDate((state: any) => ({ ...state, ...params }));
  }

  function onChangeParamsTeam(params: any) {
    setParamsTeam((state: any) => ({ ...state, ...params }));
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getFilterComponent = (page: string) => {
    const defaultParams = {
      setOpen: setFilterSelect,
      open: filterSelect,
    };

    switch (page) {
      case "deliveryStatus":
        return (
          <FilterDeliveryStatus
            onChangeParamsDate={onChangeParamsDate}
            onChangeParams={onChangeParams}
            {...defaultParams}
          />
        );
      case "Drivers":
        return (
          <FilterDrivers onChangeParams={onChangeParams} {...defaultParams} />
        );
      case "DriverStatus":
        return (
          <div className="calendarContainer">
            <Calendar
              isBoxReturn
              dateRangeLimit={1}
              position="below"
              onChangeParams={onChangeParams}
              {...defaultParams}
            />
          </div>
        );
      case "BoxReturn":
        return (
          <FilterBoxesReturn
            checkboxes={props.checkboxes}
            onChangeParams={onChangeParams}
            {...defaultParams}
            loading={loading}
          />
        );
      case "BoxCount":
        return (
          <div className="calendarContainer">
            <Calendar
              isBoxReturn
              dateRangeLimit={14}
              position="below"
              onChangeParams={onChangeParams}
              {...defaultParams}
            />
          </div>
        );
      case "Routes":
        return (
          <FilterRoutes
            checkboxes={props.checkboxes}
            onChangeParamsTeam={onChangeParamsTeam}
            {...defaultParams}
          />
        );
      case "Deliveries":
        return (
          <FilterDeliveries
            onChangeParamsDate={onChangeParamsDate}
            onChangeParams={onChangeParams}
            {...defaultParams}
          />
        );
    }
  };

  const onChangeInputFilter = (value: string) => {
    if (!(value.length >= (props.minSearchSize || 0))) return;

    debaunceFilter({ search: value, page: 1 });
  };

  const onClickFilter = () => {
    if (props.page === "TeamPage") {
      toast.warn("Função ainda em desenvolvimento.");
      return;
    }
    setFilterSelect(true);
  };

  return (
    <ToolbarWrapper className="toolbar" singleButton={!!props.singleButton} hideFilter={props.hideFilter}>
      <div className="searchAndFilter">
        <div className="searchContainer">
          {!props.hideSearch &&
            <InputFilter
              onChange={onChangeInputFilter}
              placeholder={props.placeholder as string}
            />
          }
        </div>
        {!props.hideFilter && <ButtonFilter onClickFilter={onClickFilter} />}
        <div className="filterContainer">
          {getFilterComponent(props.page as string)}
        </div>
      </div>
      <div className="buttons">
        {props.add && (
          <div className="buttonPlus">
            {!props.genericAdd && (
              <img className="plusIcon" src={PlusIcon} alt="" />
            )}
            <Button
              className={`button ${props.addText ? "long" : ""}`}
              text={props.addText || "Adicionar"}
              onClick={props.onAdd}
            />
          </div>
        )}
        {props.extraButtons &&
          props.extraButtons?.map((button, index) => (
            <ExtraButtonContainer
              buttonStyle={button.type}
              customWidth={button.customWidth}
              customMargin={button.customMargin}
              key={index}
              onClick={button.onClick}
              disabled={button.disableState}
            >
              <div className="buttonContent">
                {button.icon}
                <span>{button.text}</span>
              </div>
            </ExtraButtonContainer>
          ))}

        <div className="buttonPlanilhaContainer">
          {props.import && (
            <>
              <label
                htmlFor="file-input"
                className="custom-file-upload buttonPlanilha"
                onClick={handleButtonClick}
              >
                <img className="planilhaIcon" src={PlanilhaIcon} alt="" />
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <span className="planilhaText">Importar planilha</span>
                )}
              </label>
              <input
                name="file-input"
                ref={fileInputRef}
                type="file"
                onChange={handleFileInputChange}
                accept=".xlsx, .xlsm"
              />
            </>
          )}
          {props.export && (
            <ButtonPlanilha
              className={
                props.page === "BoxReturn"
                  ? "buttonPlanilha export"
                  : "buttonPlanilha"
              }
              onClick={props.onDownload}
            >
              <img className="planilhaIcon" src={PlanilhaIcon} alt="" />
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <span className="planilhaText">Baixar planilha</span>
              )}
            </ButtonPlanilha>
          )}
          {props.page === "Routes" && (
            <ButtonPlanilha className="buttonPdf" onClick={props.onDownload}>
              <img className="planilhaIcon" src={PDFIcon} alt="" />
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <span className="planilhaText">Baixar PDF</span>
              )}
            </ButtonPlanilha>
          )}
        </div>
        {props.page === "Routes" && <ButtonModalOverRoutesBoxes />}
      </div>
    </ToolbarWrapper>
  );
}
