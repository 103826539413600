import { ISensitiveItem, Orders } from "interfaces/ISensitiveItems";
import { OrderContainer } from "./styles";
import { boxControlService } from "services/boxControlService";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SensitiveItemEntry } from "../SensitiveItemEntry";

interface IProps {
    data: ISensitiveItem[],
    updatedList: ISensitiveItem[],
    setUpdatedList: React.Dispatch<React.SetStateAction<ISensitiveItem[]>>
}

export function SensitiveItemOrder({ data, updatedList, setUpdatedList }: IProps) {
    const [itemList, setItemList] = useState<ISensitiveItem[]>([] as ISensitiveItem[])
    const [isCollapsed, setCollapsed] = useState(true)

    const orderId = data[0]?.orderId

    const fetchData = () => {
        boxControlService.getSensitiveItems(orderId)
            .then(response => {
                setItemList(response.results)
                if (response.results.length === 0) {
                    toast.warn("Não há itens sensíveis para esse pedido.")
                    return
                }
                setCollapsed(false)
            }).catch(e => {
                toast.error(`Não foi possível buscar os itens para o pedido #${orderId}`)
            })
    }

    const handleUpdate = (element: ISensitiveItem, remove: boolean) => {
        const newList = [...updatedList]
        const index = newList.findIndex(count => count.orderId === element.orderId)

        if (index !== -1) {
            newList[index] = element
        } else {
            newList.push(element)
        }

        setUpdatedList(remove ? newList.filter(count => count.orderId !== element.orderId) : newList)
    }

    return (
        <OrderContainer isCollapsed={isCollapsed} onClick={() => isCollapsed && fetchData()}>
            <div className="header">
                <div className="large">Pedido #{orderId}</div>
                {!isCollapsed &&
                    <>
                        <div className="small">Qnt.</div>
                        <div className="small">Itens quebrados</div>
                    </>
                }
            </div>
            <div className="body">
                {itemList.map((item, index) => (
                    <>
                        <SensitiveItemEntry item={item} key={item.id} handleUpdate={handleUpdate} />
                        {index < itemList.length - 1 && <hr />}
                    </>
                ))}
            </div>
        </OrderContainer>
    )
}
