import { Line } from "./styles";
import { dateFormat } from "utils/dateFormat"
import CustomCheckBox from "components/checkbox";
import CircularProgress from '@mui/material/CircularProgress';
import { defaultTheme } from "styles/theme/default";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IOrderRoute } from "interfaces/IWaybill";

export const FormTableLine = ({ element, handleToggleSelected, selectedRows, waitingResponse, responses, dataOrderRoute }: any) => {

  const handleChecked = () => {
    return selectedRows.includes(element.routeId)
  }

  const getResultIcon = (statusCode: number) => {
    return statusCode === 200
      ? <CheckIcon sx={{ color: defaultTheme.colors.base, fontSize: 20 }} />
      : <CloseIcon sx={{ color: defaultTheme.colors.danger, fontSize: 20 }} />;
  }

  const orderRouteQuantity = dataOrderRoute?.filter(({ route }: IOrderRoute) => route === element?.routeId)?.length || 0;

  return (
    <Line className="bodyLine">
      <td>
        <CustomCheckBox handleToggle={
          () => handleToggleSelected(element.routeId)}
          checked={handleChecked()}
          align='center'
        />
      </td>
      <td align="left" className='contentTable'>
        <span>{element?.vehicle?.driver?.name}</span>
      </td>
      <td align="center">
        <span className="routes">{element.routeId}</span>
      </td>
      <td align="center">
        <span className="routes">{element.depot?.depotName}</span>
      </td>
      <td align="center">
        <span className="date">{dateFormat(element.executionDate)}</span>
      </td>
      <td align="center">
        <span className="routes">{orderRouteQuantity} de {element.routeDetails?.length}</span>
      </td>
      <td align="center">
        {waitingResponse && handleChecked() && (!responses || !responses[element.routeId]) ? (
          <CircularProgress size={16} sx={{ color: defaultTheme.colors.base }} thickness={6} />
        ) : (responses && responses[element.routeId]) ? (
          getResultIcon(responses[element.routeId])
        ) : null}
      </td>
    </Line>
  )
}
