import saveAs from "file-saver";
import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, MainContainer, SidebarContainer } from './styles';
import { useEffect, useRef, useState } from "react";
import { PageContainer } from "components/PageContainer";
import { Toolbar } from "components/Toolbar";
import { RouteCard } from 'components/DataCard/RouteCard';
import { IParams, IRoute } from 'interfaces/IWaybill';
import { useMediaQuery } from 'react-responsive';
import { AssignedRoutes } from 'components/AssignedRoutes';
import { routeService } from 'services/routeService';
import { PercentageRoutes } from 'components/PercentageRoutes';
import { toast } from 'react-toastify';
import { pdfService } from "services/downloadPdfService";
import { ILineup } from "interfaces/ILineup";
import { dateToday } from "utils/dateToday";
import { useDebaunce } from "utils/debaunce";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { boxControlService } from "services/boxControlService";
import { ICheckboxOption } from "components/checkbox";
import { ColumnProps, DataTable } from "components/Table";

const defaultColumns: ColumnProps[] = [
  {
    name: "Nome do Motorista",
    align: "left",
    minWidth: "240px",
    sortValues: true,
  },
  {
    name: "",
    align: "center",
    minWidth: "60px",
  },
  {
    name: "Rota",
    align: "center",
    sortValues: true
  },
  {
    name: "Capcd. Caixas",
    align: "center",
    sortValues: true,
    minWidth: "100px",
  },
  {
    name: "Caixas na Rota",
    align: "center",
    sortValues: true,
    minWidth: "100px",
  },
  {
    name: "Caixas na Separação",
    align: "center",
    sortValues: true,
    minWidth: "100px",
  },
  {
    name: "Rotas na Quinzena",
    align: "center",
    minWidth: "100px",
  },
  {
    name: "Rotas na Semana",
    align: "center",
    sortValues: true,
    minWidth: "100px",
  },
  {
    name: "",
  }
]

const smallScreenColumns: ColumnProps[] = [
  {
    name: "Nome",
    align: "left",
    minWidth: "240px",
    sortValues: true,
  },
  {
    name: "",
    align: "center",
    minWidth: "80px",
  },
  {
    name: "Rota",
    align: "center",
    sortValues: true,
  },
  {
    name: "Capacidade",
    align: "center",
    sortValues: true,
  },
  {
    name: "Caixas (Prev.)",
    align: "center",
    sortValues: true
  },
  {
    name: "Caixas (Sep.)",
    align: "center",
    sortValues: true
  },
  {
    name: "Qtd. Rotas Quinzena",
    align: "center",
    minWidth: "100px",
    sortValues: true,
  },
  {
    name: "Qtd. Rotas Sem.",
    align: "center",
    minWidth: "100px",
    sortValues: true,
  },
  {
    name: "",
  }
]

export function RoutePage() {
  const smallScreen = useMediaQuery({ maxWidth: 820 })
  const [dataDriverTable, setDataDriverTable] = useState<IRoute[]>([])
  const [waybills, setWaybills] = useState<IRoute[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [params, setParams] = useState<IParams>({ page: 1, pageSize: 300, search: '', notPaginated: true, date: dateToday(1) })
  const [loading, setLoading] = useState(false);
  const [lineUp, setLineup] = useState<ILineup[]>([] as ILineup[])
  const [paramsTeam, setParamsTeam] = useState({ teamId: '' })
  const [waitingResponse, setResponse] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const [confirmation, setConfirmation] = useState(false);
  const debaunceFilterDate = useDebaunce({ fn: onChangeParamsTeam, delay: 500 })
  const [showVehiclesInUse, setShowVehiclesInUse] = useState(false)
  const [boxesByRouteCarambola, setBoxesByRouteCarambola] = useState({})
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const sortColumnNameRef = useRef("");
  let columns = smallScreen ? smallScreenColumns : defaultColumns

  const fetchData = async () => {
    try {
      setLoading(true)
      const [response, wayList, boxesCountData] = await Promise.all([
        routeService.getVehicleRotation(params),
        routeService.getWaybillLineUp(params),
        getBoxesCountData()
      ])

      setBoxesByRouteCarambola(boxesCountData)

      setDataDriverTable(response.results);
      setTotalPages(response.totalPages)
      setWaybills(wayList.results)
    } catch (error) {
      toast.error('Erro ao buscar dados no sistema')
    }
    setLoading(false)
  }

  const getBoxesCountData = async () => {


    const startCountDate = new Date()

    const hourNow = startCountDate.getHours()

    if (hourNow > 12) {
      startCountDate.setHours(12, 0)
    }

    if (hourNow < 12) {
      startCountDate.setHours(12, 0)
      startCountDate.setDate(startCountDate.getDate() - 1)
    }

    const endCountDate = new Date(startCountDate)
    endCountDate.setDate(endCountDate.getDate() + 1)


    const response = await boxControlService.getOrdersBoxCount({
      startCountDate: startCountDate.toISOString(),
      endCountDate: endCountDate.toISOString()
    })

    const groupBoxesByRoute = response.results.reduce((acc: { [key: string]: number }, order: { route: number, boxesSent: number }) => {

      acc[String(order.route)] = order.boxesSent + (acc[String(order.route)] || 0)
      return acc
    }, {})

    return groupBoxesByRoute
  }

  const fillLineup = (element: IRoute[]) => {
    let newList: ILineup[] = []
    element.forEach((route, index) => {
      newList[index] = {
        vehicleId: route.vehicle?.id || 0,
        waybillId: route.id
      }
    })
    setLineup(newList)
  }

  const joinData = () => {
    const combinedData = dataDriverTable?.map((driverData: any) => {
      const waybill = waybills?.find(
        (wb: any) => wb.vehicle?.id === driverData?.id
      )
      return {
        ...driverData,
        ...waybill,
      }
    })

    const mergedData = combinedData?.filter((item: any) => item !== null);
    return mergedData
  }

  const sortValues = (): IRoute[] => {
    if (sortOrder === "") return newData.slice()
    const compare = (a: any, b: any, sortOrder: string, getValue: (item: any) => number | string) => {
      const aValue = getValue(a);
      const bValue = getValue(b);

      if (sortOrder === 'asc') {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    };
    const sortFunction = (getValue: (item: any) => number | string) => newData.slice().sort((a, b) => compare(a, b, sortOrder, getValue));
    switch (sortColumnName) {
      case 'Nome do Motorista':
      case 'Nome':
        return sortFunction(item => item.driver?.name || "");
      case 'Rota':
        return sortFunction(item => Number(item.route || Infinity));
      case 'Capcd. Caixas':
      case 'Capacidade':
        return sortFunction(item => Number(item.capacity));
      case 'Caixas na Rota':
      case 'Caixas (Prev.)':
        return sortFunction(item => Number(item.boxesPlanned));
      case 'Rotas na Semana':
      case 'Qtd. Rotas':
        return sortFunction(item => Number(item.recentDeliveries || Infinity));
      default:
        return newData.slice();
    }
  };

  const handleSaveLineup = (element: ILineup[]) => {
    toast.info("Aguarde...")
    routeService.updateLineup(element)
      .then(a => {
        fetchData()
        toast.success("Escala feita com sucesso!")
      })
      .catch(err => {
        toast.error("Houve um erro ao fazer a escala")
      })
  }

  const handlePutLineup = (element: ILineup) => {
    toast.info("Aguarde...")
    routeService.putLineup(element)
      .then(a => {
        fetchData()
        toast.success("Dados atualizados com sucesso!")
      }).catch(err => {
        toast.error("Houve um erro ao atualizar os dados")
      })
  }

  const handleMirrorAgile = () => {
    toast.info("Aguarde, isso pode levar algum tempo...")
    setResponse(true)
    routeService.mirrorAgile()
      .catch(err => {
        toast.error("Houve um erro ao espelhar o Agile, tente novamente em alguns minutos")
      })
      .finally(() => setResponse(false))
  }

  const handleMirrorAgileConfirmed = () => {
    if (confirmation) {
      setConfirmation(false);
      handleSaveLineup(lineUp);
    }
  }

  const handleMirrorAgileCancelled = () => {
    setConfirmation(false);
  }

  const handleSortClick = (event: any, column: string) => {
    sortColumnNameRef.current = column;
    setPopupAnchor(event.currentTarget);
  }

  const handleClosePopup = () => {
    setPopupAnchor(null);
  }

  const handleSort = (order: string) => {
    setSortOrder(order);
    setSortColumnName(sortColumnNameRef.current)
    handleClosePopup();
  }

  const handleDist = (routes: number[], newTransshipCode: number, deliveryDate?: string) => {
    routeService.updateTransship(newTransshipCode, routes, dateToday(1))
      .then(a => fetchData())
  }

  function onChangeParamsTeam(params: any) {
    setParamsTeam((state: any) => ({ ...state, ...params }))
  }

  const downloadFile = async () => {
    const byTeam = true
    try {
      setLoading(true);
      if (paramsTeam.teamId !== '') {
        const response = await pdfService.downloadPdf(paramsTeam.teamId)
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, 'Rotas-Maracujá.pdf')
      }
      if (!paramsTeam.teamId || paramsTeam.teamId === '') {
        const response = await pdfService.downloadZip(byTeam)
        const blob = new Blob([response], { type: 'application/zip' });
        saveAs(blob, 'Rotas-Maracujá.zip')
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os dados.');
    } finally {
      setLoading(false);
    }
  }

  const handleToggleFilter = () => {
    setShowVehiclesInUse(!showVehiclesInUse);
  };

  const checkboxes: ICheckboxOption[] = [
    {
      id: 'showVehiclesInUse',
      text: 'Apenas Veículos Ativos',
      handleToggle: handleToggleFilter,
      state: showVehiclesInUse
    }
  ];

  useEffect(() => {
    fetchData()
  }, [params])

  useEffect(() => {
    fillLineup(waybills)
  }, [waybills])

  const newData: any[] = joinData()

  return (
    <Container>
      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Atribuição de Rotas</h3>
              <Toolbar
                params={params}
                paramState={setParams}
                paramsTeam={paramsTeam}
                paramsTeamState={setParamsTeam}
                loadingState={loading}
                placeholder="Motorista ou veículo"
                add={true}
                genericAdd={true}
                addText="Fazer Escala"
                onAdd={() => setConfirmation(true)}
                page='Routes'
                checkboxes={checkboxes}
                onDownload={downloadFile}
                extraButtons={[
                  { type: "outlined", text: "Espelhar Agile", onClick: () => handleMirrorAgile(), disableState: waitingResponse }
                ]}
              />
              {confirmation && (
                <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
                  <DialogTitle>Confirmação</DialogTitle>
                  <DialogContent>
                    <p>Deseja realmente fazer a escala no Agile?</p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleMirrorAgileCancelled} color="error">Não</Button>
                    <Button onClick={handleMirrorAgileConfirmed} color="success">Sim</Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
            <div className="spacer" />
            <DataTable
              loading={loading}
              columns={columns}
              actions={{
                handleSortClick: handleSortClick,
                handleSort: handleSort,
                popupAnchor: popupAnchor,
                setPopupAnchor: setPopupAnchor
              }}
              noHeader
            >
              {sortValues()?.filter((item: IRoute) => {
                const hasName = !!item?.driver?.name;
                const SelectedTeam = paramsTeam.teamId === '' ||
                  (item?.driver?.team?.id ?? 0) === (paramsTeam.teamId === undefined ? 0 : Number(paramsTeam.teamId));
                if (showVehiclesInUse) {
                  return item?.inUse && hasName && SelectedTeam;
                } else {
                  return hasName && SelectedTeam;
                }
              })
                .map((element: IRoute) => (
                  <RouteCard
                    key={element.id}
                    element={element}
                    lineups={lineUp}
                    saveLineup={handlePutLineup}
                    handleChangeDist={handleDist}
                    boxesByRouteCarambola={boxesByRouteCarambola}
                  />
                ))}
            </DataTable>
            <div style={{ height: '70px' }} />
            {!loading &&
              <div className="routeStats">
                <div className="leftCol">
                  {expanded && !smallScreen && <AssignedRoutes data={newData} />}
                </div>
                <div className="rightCol">
                  {expanded && <PercentageRoutes dataDriver={newData} setParamsTeam={setParamsTeam} paramsTeam={paramsTeam} />}
                  <button className="expandStatsBtn" onClick={() => setExpanded(!expanded)}>
                    {expanded ? <ArrowForwardIosRounded /> : <ArrowBackIosNewRounded />}
                  </button>
                </div>
              </div>
            }
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}
