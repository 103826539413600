import saveAs from "file-saver";
import PlanilhaIcon from 'assets/planilhaIcon.svg'
import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, Line, MainContainer, SidebarContainer } from './styles';
import { ColumnProps, DataTable } from "components/Table";
import { baseURL } from "settings";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { PageContainer } from 'components/PageContainer'
import { ButtonFilter } from "components/ButtonFilter";
import FilterIcon from 'assets/filterIcon.svg'
import { IPunctuality } from "interfaces/IPunctuality";
import { FilterPunctuality } from "components/FilterPunctuality";
import { toast } from "react-toastify";
import { ButtonPlanilha } from "components/Button";
import { CircularProgress } from "@mui/material";

const columns: ColumnProps[] = [
  {
    name: "Motorista",
    align: "center",
  },
  {
    name: "Pontualidade",
    align: "center",
  },
  {
    name: "Modelo do veículo",
    align: "center",
  },
  {
    name: "Região",
    align: "center",
  },
]

export function Punctuality() {
  const [searchTerm, setSearchTerm] = useState('');
  const [dataPagination, setDataPagination] = useState<IPunctuality[]>([])
  const [filterSelect, setFilterSelect] = useState(false)
  const [params, setParams] = useState({})
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(false)

  const fetchData = () => {
    setLoading(true)
    api.get(`${baseURL}/v1/delivery/driver/punctuality`, { params })
      .then((response) => {
        setDataPagination(response);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const downloadFile = async () => {
    try {
      setLoading(true)
      const response = await api.get(`${baseURL}/v1/delivery/driver/punctuality`, {
        params: {
          "download": true
        },
        responseType: 'arraybuffer'
      });
      const blob = new Blob([response], {
        type: 'application/ms-excel;charset=utf-8'
      });
      saveAs(blob, 'Pontualidade-Motoristas.xlsx');
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os dados de pontualidade.');
    } finally {
      setLoading(false)
    }
  };

  function onChangeParams(params: any) {
    setParams(state => ({ ...state, ...params }))
  }

  const orderByPunctuality = (a: IPunctuality, b: IPunctuality) => {
    return b.punctuality - a.punctuality
  }

  const orderByName = (a: IPunctuality, b: IPunctuality) => {
    return a.name.localeCompare(b.name)
  }

  useEffect(() => {
    fetchData()
  }, [params]);

  return (
    <Container>
      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Pontualidade de motoristas</h3>
              <div className="toolBar">
                <button className="buttonFilterContainer" onClick={() => setFilterSelect(true)}>
                  <img className="filterIcon" src={FilterIcon} alt="" />
                  <ButtonFilter className="buttonFilter" text="Filtrar" />
                </button>
                <div className="buttonPlanilhaContainer" >
                  <ButtonPlanilha className="buttonPlanilha" onClick={downloadFile} >
                    <img className="planilhaIcon" src={PlanilhaIcon} alt="" />
                    {loading ? <CircularProgress size={24} color="inherit" /> : <span className="planilhaText">Baixar planilha</span>}
                  </ButtonPlanilha>
                </div>
              </div>
              <FilterPunctuality open={filterSelect} setOpen={setFilterSelect} onChangeParams={onChangeParams} />
            </div>
            <DataTable
              loading={loading}
              columns={columns}
            >
              {dataPagination?.filter(item => item?.driverId?.toString().includes(searchTerm?.trim()))
                .sort(order ? orderByPunctuality : orderByName)
                .map(element => (
                  <Line className="bodyLine" key={element.driverId}>
                    <td align="center" className='contentTable'>{element?.name || "Sem nome"}</td>
                    <td align='center' className='contentTable'>{element?.punctuality}%</td>
                    <td align="center" className='contentTable'>Modelo</td>
                    <td align='center' className='contentTable contentName'>{element?.region}</td>
                  </Line>
                ))}
            </DataTable >
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}
