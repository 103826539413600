import { SwitchContainer } from "./styles";
import { Link } from "react-router-dom";

interface Tab {
    label: string;
    path: string;
}

interface IProps {
    tabs: Tab[];
    selectedPage: string;
}

export function TabBar({ tabs, selectedPage }: IProps) {
    return (
        <SwitchContainer>
            {tabs.map((tab) => (
                <div
                    key={tab.path}
                    className={`buttonContainer ${tab.path === selectedPage ? "selected" : ""}`}
                >
                    <Link to={tab.path}>{tab.label}</Link>
                </div>
            ))}
        </SwitchContainer>
    );
}
