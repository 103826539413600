import { useState } from "react"
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, FormContainer, InputContainer } from "./styles";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Logo } from "components/Logo";
import { useForm } from 'react-hook-form'
import { ILogin } from "interfaces/ILogin";
import SigninIllust from 'assets/signinIllust.svg'
import { useAuth } from "contexts/AuthContext";
import * as Yup from 'yup'
import { Box } from "@mui/material";


const validateSchema = Yup.object().shape({
  username: Yup.string().required('Login obrigatório.'),
  password: Yup.string().required('Senha obrigatória.')
})

export function SignIn() {
  const { handleSignIn } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, formState } = useForm<ILogin>({
    resolver: yupResolver(validateSchema),
  })

  const { errors } = formState

  const onSubmit = async (data: { username: string, password: string }) => {
    setIsLoading(true);
    await handleSignIn(data);
    setIsLoading(false);
  }

  return (
    <Container>
      <img className='illust' src={SigninIllust} alt="illust" />
      <FormContainer className="formContainer">
        <Logo />
        <InputContainer>
          <h2>Olá, bem-vindo ao <br /> Maracujá</h2>
          <Box component="form" className="formWrapper" onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" gap="0.3rem">
              <span>Login</span>
              <Input
                {...register('username')}
                className={errors.username ? 'error' : ""}
                placeholder="Usuário"
                error={errors.username?.message}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap="0.3rem">
              <span>Senha</span>
              <Input
                {...register('password')}
                className={errors.password ? 'error' : ""}
                placeholder="Senha"
                error={errors.password?.message}
                type="password"
              />
            </Box>

            <Box mt="1rem">
              <Button variant="primary" type="submit" disabled={isLoading}>
                Entrar
              </Button>
            </Box>
          </Box>
        </InputContainer>
      </FormContainer>
    </Container>
  )
}
