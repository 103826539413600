import { LinearProgress, Popover } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { ReactNode } from 'react';
import { PopupButton, Table, TableContainer } from './styles';
import PageLoader from './PageLoader';
import React from 'react';
import { NoDataMessage } from 'components/NoDataMessage/styles';

export type ColumnProps = {
  name: string | ReactNode;
  minWidth?: string;
  align?: "center" | "left" | "right";
  sortValues?: boolean;
};

type TitleProps = {
  loading: boolean;
  columns: ColumnProps[];
  children: ReactNode;
  actions?: {
    handleSortClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>, column: string) => void;
    handleSort: (order: string) => void;
    popupAnchor: any;
    setPopupAnchor: React.Dispatch<React.SetStateAction<any>>;
  };
  isPopup?: boolean;
  hasBorderRadius?: boolean;
  noHeader?: boolean;
};

export function DataTable({
  loading,
  columns,
  children,
  actions,
  hasBorderRadius = true,
  noHeader,
  isPopup = false
}: TitleProps) {

  const handleClosePopup = () => {
    actions?.setPopupAnchor(null);
  };

  const hasData = React.Children.count(children) > 0;

  return (
    <>
      <TableContainer>
        {loading && !isPopup && (
          <PageLoader isPopup={isPopup} />
        )}

        {!loading && !hasData && (
          <NoDataMessage isPopup={isPopup}>
            <h3>Não há dados para exibir :(</h3>
            <p>Verifique os filtros aplicados e tente novamente.</p>
          </NoDataMessage>
        )}

        {((!loading && hasData) || isPopup) && (
          <Table style={{ position: 'relative' }} hasBorderRadius={hasBorderRadius} noHeader={noHeader} actions={!!actions}>
            <thead>
              <tr className='headerTable'>
                {columns.map((column, index) => (
                  <th key={index} className={`col`} align={column.align} style={{ minWidth: column.minWidth || '150px', flex: 1 }}>
                    <div className='columnAttributes'>
                      {column.name}
                      {actions && column.sortValues && (
                        <ArrowDownward
                          className={`arrowIcon`}
                          onClick={(e) => actions.handleSortClick(e, String(column.name))}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {loading && <LinearProgress color="success" style={{ position: 'absolute', width: '100%' }} />}
            <tbody className='bodyTable'>
              {children}
            </tbody>
          </Table>
        )}
      </TableContainer>

      <Popover
        open={Boolean(actions?.popupAnchor)}
        anchorEl={actions?.popupAnchor}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PopupButton onClick={() => actions?.handleSort('asc')}>
          <ArrowUpward className='arrowIcon' />
          Ordem crescente
        </PopupButton>
        <PopupButton onClick={() => actions?.handleSort('desc')}>
          <ArrowDownward className='arrowIcon' />
          Ordem decrescente
        </PopupButton>
      </Popover>
    </>
  );
}
